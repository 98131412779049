import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {API_PATHS} from '../configs/api'
import store from "../app/store";



export const mediaLiveApi = createApi({
    reducerPath: 'mediaLiveApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_PATHS.BASE_URL,
        prepareHeaders: (headers, {getState}) => {
            const accessToken = (getState() as any).auth.accessToken
            if (accessToken && accessToken.jwtToken) {
                headers.set('Authorization', `Bearer ${accessToken.jwtToken}`)
            }
            return headers
        },
    }),
    endpoints: (builder) => ({

        createLiveInput: builder.mutation<CreateLiveInputResponse, CreateLiveInputRequest>({
            query: ({eventId, inputName, streamName}) => ({
                url: `${API_PATHS.MEDIA_LIVE.CREATE_INPUT}?eventId=${eventId}&inputName=${inputName}&streamName=${streamName}`,
                method: "POST",
            }),
            async onQueryStarted({eventId, inputName, streamName}, {queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data);
                } catch (error) {
                    console.log(`create event error`);
                }
            },
        }),
        goLiveById: builder.mutation<ChannelStartResponse, ChannelStartRequest>({
            query: ({channelId,liveEventId}) => ({
                url: `${API_PATHS.MEDIA_LIVE.START_CHANNEL}?id=${channelId}&eventId=${liveEventId}`,
                method: "POST",
            }),
            async onQueryStarted({channelId}, {queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data);
                } catch (error) {
                    console.log(`create event error`);
                }
            },

        }),
        stopLiveChannel: builder.mutation<LiveChannelStopResponse, LiveChannelStopRequest>({
            query: ({channelId,liveEventId}) => ({
                url: `${API_PATHS.MEDIA_LIVE.STOP_CHANNEL}?id=${channelId}&eventId=${liveEventId}`,
                method: "POST",
            }),
            async onQueryStarted({channelId}, {queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data);
                } catch (error) {
                    console.log(`stop live error`);
                }
            },
        }),
        deleteMediaLiveInput: builder.mutation<void, DeleteMediaLiveInput>({
            query: ({mediaLiveInputId}) => ({
                url: `${API_PATHS.MEDIA_LIVE.DELETE_INPUT}?id=${mediaLiveInputId}`,
                method: "DELETE",
            }),
        }),
        deleteMediaLiveChannel: builder.mutation<void, DeleteMediaLiveChannel>({
            query: ({mediaLiveChannelId,mediaLiveInputId}) => ({
                url: `${API_PATHS.MEDIA_LIVE.DELETE_CHANNEL}?id=${mediaLiveChannelId}&inputId=${mediaLiveInputId}`,
                method: "DELETE",
            }),
        }),
        //?name=${name}&eventId=${eventId}&mediaLiveInputId=${mediaLiveInputId}&mediaPackageChannelId=${mediaPackageChannelId}&mediaPackageChannelUrlParam=${mediaPackageChannelUrlParam}&mediaPackageChannelUsernameParam=${mediaPackageChannelUsernameParam}&mediaPackageChannelPasswordParam=${mediaPackageChannelPasswordParam}
        createChannel:builder.mutation<CreateChannelResponse,CreateChannelRequest>({
            query:({name,eventId,mediaLiveInputId,mediaPackageChannelId,mediaPackageChannelUrlParam,mediaPackageChannelUsernameParam,mediaPackageChannelPasswordParam,facebookStreamKey, youtubeStreamKey, twitchStreamKey,userID,fullHd,ultraHd})=>({
                url:`${API_PATHS.MEDIA_LIVE.CREATE_CHANNEL}?name=${name}&eventId=${eventId}&mediaLiveInputId=${mediaLiveInputId}&mediaPackageChannelId=${mediaPackageChannelId}&mediaPackageChannelUsernameParam=${mediaPackageChannelUsernameParam}&mediaPackageChannelUrlParam=${mediaPackageChannelUrlParam}&mediaPackageChannelPasswordParam=${mediaPackageChannelPasswordParam}&facebookStreamKey=${facebookStreamKey}&youtubeStreamKey=${youtubeStreamKey}&twitchStreamKey=${twitchStreamKey}&fullHd=${fullHd}&ultraHd=${ultraHd}`,
                method:"POST"
            }),

        }),

        sshCreateParam:builder.mutation<CreateShhParamRequest,CreateShhParamRequest>({
            query:({name,value})=>({
                url:`${API_PATHS.SSH.CREATE_PARAM}?name=${name}&value=${value}`,
                method:"POST"
            }),


        }),

    }),
})

export const {useCreateLiveInputMutation, useDeleteMediaLiveChannelMutation, useGoLiveByIdMutation, useStopLiveChannelMutation, useDeleteMediaLiveInputMutation,useCreateChannelMutation,useSshCreateParamMutation} = mediaLiveApi

interface CreateLiveInputRequest {
    eventId: string
    inputName: string
    streamName: string
}
interface CreateShhParamRequest{
    name:string
    value:string
}
interface CreateChannelRequest{
    name:string
    eventId:string
    mediaLiveInputId:string
    mediaPackageChannelId:string
    mediaPackageChannelUrlParam:string
    mediaPackageChannelUsernameParam:string
    mediaPackageChannelPasswordParam:string
    facebookStreamKey:string
    youtubeStreamKey:string
    twitchStreamKey:string
    userID:string
    fullHd:string
    ultraHd:string
}
interface CreateChannelResponse{
    name:string
    eventId:string
    mediaLiveInputId:string
    mediaPackageChannelId:string
    mediaPackageChannelUrlParam:string
    mediaPackageChannelUsernameParam:string
    mediaPackageChannelPasswordParam:string
    facebookStreamKey:string
    youtubeStreamKey:string
    twitchStreamKey:string
    fullHd:string
    ultraHd:string
}

interface CreateLiveInputResponse {
    eventId: string
    inputName: string
    streamName: string
}

interface ChannelStartResponse {
    channelId: string
    liveEventId: string

}

interface ChannelStartRequest {
    channelId: string
    liveEventId: string

}

interface LiveChannelStopResponse {
    channelId: string
    liveEventId: string

}

interface LiveChannelStopRequest {
    channelId: string
    liveEventId: string

}

interface DeleteMediaLiveInput {
    mediaLiveInputId: string
}

interface DeleteMediaLiveChannel {
    mediaLiveChannelId: string
    mediaLiveInputId: string
}