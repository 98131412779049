import React from 'react';
import {Grid, Typography, Button, TextField, IconButton} from '@mui/material';
import {styled} from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom';


const LeftSide = styled('div')({
    backgroundColor: 'White',
    padding: '16px',
    justifyContent: 'space-between',

});
const RightSide = styled('div')({
    backgroundColor: '#014361',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    height: '620px',
    borderRadius: '0 10px 0 0',

});

const EditProfile = () => {
    return (

        <div style={{
            display: 'flex',
            height: '100%',
            margin: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.29)',
        }}>
            <Grid container>
                {/* Left Side */}
                <Grid item xs={9} sx={{}}>
                    <div>
                        <LeftSide
                            sx={{p: '2%', ml: '10%'}}
                        >
                            <Typography variant='h4' sx={{
                                p: '10px',
                                mb: '5%',
                                fontWeight: 'bold',
                                letterSpacing: '0.25px'
                            }}>Update Profile</Typography>
                            <div style={{
                                margin: '4% 25% 4% 0',
                            }}>
                                <TextField
                                    label="User Name"
                                    defaultValue="Samantha Andrew"
                                    variant="outlined"
                                    placeholder='Enter your user name'
                                    fullWidth

                                    sx={{
                                        m: '1%',
                                        display: 'flex'
                                    }}
                                />
                            </div>
                            <div style={{
                                margin: '4% 25% 4% 0',
                            }}>
                                <TextField
                                    label="Name"
                                    defaultValue="Samantha Andrew"
                                    variant="outlined"
                                    placeholder='Enter your name'
                                    fullWidth

                                    sx={{
                                        m: '1%',
                                        display: 'flex'
                                    }}
                                />
                            </div>
                            <div style={{
                                margin: '4% 25% 4% 0',
                            }}>
                                <TextField
                                    label="Email"
                                    defaultValue="riddoiwemmeipoi@yopmail.com"
                                    variant="outlined"
                                    placeholder='Enter valid email'
                                    fullWidth

                                    sx={{
                                        m: '1%',
                                        display: 'flex'
                                    }}
                                />
                            </div>
                            <div style={{
                                margin: '4% 25% 4% 0',
                            }}>
                                <TextField
                                    label="Contact Number"
                                    defaultValue="+1 505-644-8977"
                                    variant="outlined"
                                    placeholder='Enter contact number'
                                    fullWidth

                                    sx={{
                                        m: '1%',
                                        display: 'flex'
                                    }}
                                />
                            </div>
                            <div style={{
                                margin: '4% 25% 4% 0',
                            }}>
                                <TextField
                                    label="Address"
                                    defaultValue="8814 Cartwright Views, Apt. 659, A8S 0D8, Wilfredfurt, Alberta, Canada"
                                    variant="outlined"
                                    placeholder='Enter your address'
                                    fullWidth

                                    sx={{
                                        m: '1%',
                                        display: 'flex'
                                    }}
                                />
                            </div>

                        </LeftSide>
                    </div>
                </Grid>
                {/* Right Side */}
                <Grid item xs={3}>
                    <div>
                        <RightSide>
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        fontSize: '24',
                                        top: '10px',
                                        right: '10px',
                                        color: '#fff',
                                    }}
                                    onClick={() => {

                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}>
                                    <img src="https://source.unsplash.com/random/230x290" alt="Your Image" width="250"
                                         height="350"
                                         style={{
                                             borderRadius: '5px',
                                             boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                         }}
                                    />
                                </div>
                            </div>
                        </RightSide>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        margin: '5%',
                        borderRadius: '4px',
                    }}>
                        <Link to="" style={{textDecoration: 'none'}}>
                            <Button variant="contained" size="large" sx={{background: '#014361'}}>
                                Save Changes
                            </Button>
                        </Link>

                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
export default EditProfile;
