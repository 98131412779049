import {Middleware, MiddlewareAPI, configureStore, isRejectedWithValue} from "@reduxjs/toolkit";
import {authApi} from "../api/authApi";
import {userApi} from '../api/userApi'
import authReducer, { logoutSuccess, setTokenExpired } from "../api/slices/authSlice";
import userReducer from "../api/slices/userSlice";
import liveReducer from "../api/slices/liveSlice";
import {eventApi} from "../api/eventApi";
import {mediaLiveApi} from "../api/mediaLiveApi";
import {mediaPackageApi} from "../api/mediaPackageApi";


// Define a function to save the state to local storage
function saveStateToLocalStorage(state: any) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("reduxState", serializedState);
    } catch (e) {
        console.log(e);
    }
}

const authInterceptor = ({ dispatch }:any) => (next:any) => (action:any) => {
  if (action.status === 401) {
   console.log("unAuth")
  } else {
    next(action);
  }

  if(action?.error?.message === 'Rejected'){
        console.log("Rejected Request");
        if(action?.payload?.status==='FETCH_ERROR'){
                console.error("Fetch Error");
                //dispatch(logoutSuccess());
                //window.location.reload();
                dispatch(setTokenExpired({isExpired: true}));
        }
  }
};

// Define a function to load the state from local storage
function loadStateFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem("reduxState");
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

// Load the state from local storage when creating the store
const persistedState = loadStateFromLocalStorage();

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        live: liveReducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [eventApi.reducerPath]: eventApi.reducer,
        [mediaLiveApi.reducerPath]: mediaLiveApi.reducer,
        [mediaPackageApi.reducerPath]: mediaPackageApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            userApi.middleware,
            eventApi.middleware,
            mediaLiveApi.middleware,
            mediaPackageApi.middleware,
        ).concat(authInterceptor),  
    preloadedState: persistedState, // set the preloadedState with the persisted state
});
// Subscribe to the store to save the state to local storage whenever it changes
store.subscribe(() => {
    saveStateToLocalStorage(store.getState());
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
