import React, {useEffect, useState} from 'react';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import dayjs, {Dayjs} from "dayjs";
import Tooltip from "@mui/material/Tooltip";
import {Box} from "@mui/material";


export interface BasicTimePickerProps {
    onTimeChange: (selectedTime: Dayjs | null) => void;
    isDisablePast: boolean;
    isDisable: boolean;
    defaultValue: Dayjs | null;
}

export default function BasicTimePicker(props: BasicTimePickerProps) {

    const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
    const now = new Date();
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes());
    const handleTimeChange = (time: Dayjs | null) => {
        console.log("Selected Time:", time);
        setSelectedTime(dayjs(time));
        props.onTimeChange(dayjs(time));
    };

    if(props.defaultValue){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(()=>{
            setSelectedTime(props.defaultValue);
        }, []);

    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLImageElement>) => {
        e.preventDefault();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
                {props.isDisable ? (
                    <Tooltip title="Select a Date First" placement="top-start" arrow>
                        <Box sx={{ width: "100%" }}>
                        <TimePicker
                            label="Time *"
                            value={selectedTime}
                            onChange={handleTimeChange}
                            slotProps={{
                                textField: { onKeyDown: onKeyDown },
                            }}
                            disablePast={props.isDisablePast}
                            disabled={props.isDisable}
                            sx={{ flex: 1, flexBasis: "100%", width: "100%" }}
                        />
                        </Box>
                    </Tooltip>
                ) : (
                    <TimePicker
                        label="Time *"
                        value={selectedTime}
                        onChange={handleTimeChange}
                        slotProps={{
                            textField: { onKeyDown: onKeyDown },
                        }}
                        disablePast={props.isDisablePast}
                        disabled={props.isDisable}
                        sx={{ flex: 1, flexBasis: "50%" }}
                    />
                )}


            </DemoContainer>
        </LocalizationProvider>
    );
}


