import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {API_PATHS} from '../configs/api'
import {setUser, UserState} from './slices/userSlice'
import {getEncodedString} from '../utils/query-string'

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_PATHS.BASE_URL,
        prepareHeaders: (headers, {getState}) => {
            const accessToken = (getState() as any).auth.accessToken
            if (accessToken && accessToken.jwtToken) {
                headers.set('Authorization', `Bearer ${accessToken.jwtToken}`)
            }
            return headers
        },
    }),
    endpoints: (builder) => ({

        updateUser: builder.mutation<
            UpdateUserResponse,
            UpdateUserRequest
        >({
            query: ({id, body}) => ({
                url: `${API_PATHS.USER.UPDATE_USER_BY_ID}?id=${id}`,
                method: "POST",
                body: body,
            }),
            async onQueryStarted({body}, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data);
                } catch (error) {
                    console.log(`Email verification error`);
                }
            },
        }),

        getUserById: builder.query<UserState, string>({
            query: (id) => `${API_PATHS.USER.GET_USER_BY_ID}?${{id}}`,
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    const response = await queryFulfilled
                    console.log(`User data : ${JSON.stringify(response)}`)
                    dispatch(setUser(response.data))
                } catch (err) {
                    console.error('Failed to fetch user data:', err)
                }
            },
        }),

        getUserByEmail: builder.query<UserState, string>({
            query: (email: string) =>
                `${API_PATHS.USER.GET_USER_BY_EMAIL}?${getEncodedString({email})}`,
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    const response = await queryFulfilled
                    console.log(`User data : ${JSON.stringify(response)}`)
                    dispatch(setUser(response.data))
                } catch (err) {
                    console.error('Failed to fetch user data:', err)
                }
            },
        }),
        deleteUserById: builder.mutation<void, DeleteUserPayload>({
            query: ({id}) => ({
                url: `/users/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
})

export const {
    useUpdateUserMutation,
    useGetUserByIdQuery,
    useGetUserByEmailQuery,
    useDeleteUserByIdMutation,
} = userApi

export interface CreateUserPayload {
    name: string
    address: string
    contactNumber: string
    email: string
    role: string
    s3Uri: string
}

interface UpdateUserResponse {
    name: string
    address: string
    contactNumber: string
}

interface UpdateUserRequest {

    id: string;
    body: {
        name: string
        address: string
        contactNumber: string
    };
}

interface DeleteUserPayload {
    id: string
}
