import React, {useState} from 'react';
import {
    Box,IconButton
} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import unsecuredCopyToClipboard from "../utils/unsecuredCopyToClipboard";
import {toast} from "react-toastify";

interface copytextprops {
    val: string
}

const CopyText = ({val}: copytextprops) => {

    const [text, setText] = useState<string>(val); // Add the type for 'text' state variable
    const [open, setOpen] = useState(false);


    const copy = async () => {
        try {
            setOpen(true);
            toast.info('Copied to clipboard', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(text);
            } else {
                unsecuredCopyToClipboard(text);
            }
        } catch (err) {
            console.error('Copy failed:', err)
        }

        setTimeout(() => {
        }, 2000);

    }

    return (
        <Box sx={{mb:2}}>
            <TextField
                label="iframe"
                defaultValue={val}
                margin="normal"
                fullWidth
                id="filled-multiline-flexible"
                multiline
                rows={3}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={copy} color="primary">
                                <ContentCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

        </Box>
    );
};

export default CopyText;

