import {Button} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import React, { useState } from "react";
import {useGoLiveByIdMutation} from "../api/mediaLiveApi";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useNavigate} from "react-router-dom";
import Progress from "./Progress";

interface ScheduleDetailsProps {
    channelSettings: {
        channelDetails: any;
        liveId: any;
        outputUrl: any;
        eventId: any;
        channelStatus: boolean
        title: string;
    };
}

const GoLiveButton = (props: ScheduleDetailsProps) => {
    const [goLiveById] = useGoLiveByIdMutation();
    const channelId = props.channelSettings.channelDetails;
    const liveEventId = props.channelSettings.eventId;
    const status = props.channelSettings.channelStatus
    const streamName = props.channelSettings.title

    const originEndpoint = props.channelSettings.outputUrl;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleGoLive = async () => {
        try {
            setIsLoading(true);
            await goLiveById({channelId, liveEventId});
            console.log(`channelId : ${channelId} `);
            navigate(`/go-live?id=${channelId}&url=${originEndpoint}&eventId=${liveEventId}&title=${streamName}`);
            // window.location.href = `/go-live?id=${channelId}&url=${originEndpoint}`;
        } catch (error) {
            console.log(`Error:`, error);
            setIsLoading(false);
        }
    };
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

    if (isLoading) {
        return <Progress/>;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button
                variant="contained"
                disabled={(status ? true : false || channelId ? false : true)}
                sx={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.text.primary,
                    textTransform:'capitalize',
                    width:'120px',
                }}
                onClick={handleClickOpen}
            >
                go live &nbsp; <PlayCircleOutlineIcon/>
            </Button>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>
                            Are you sure you want <b>start the stream now ?</b>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        m: "10px",
                        px: "10px",
                    }}
                >

                    <Button
                        onClick={handleGoLive}
                        autoFocus
                        color="info"
                        variant="contained"
                        size="small"
                        sx={{
                            ml: '30px',
                            textTransform: 'capitalize',
                            background: theme.palette.secondary.main,
                            color: theme.palette.text.primary,
                            width: '100px',
                            '&:hover': {
                                background: '#362365',
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                textTransform: "capitalize",
                            }}
                        >
                            Yes
                        </Typography>
                    </Button>

                    <Button
                        autoFocus
                        onClick={handleClose}
                        color="info"
                        variant="outlined"
                        size="small"
                        sx={{
                            color: "white",
                            mr: "20px",
                            px: "40px",
                            width: '100px',
                        }}
                    >
                        <Typography
                            sx={{
                                textTransform: "capitalize",
                            }}
                        >
                            No
                        </Typography>
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default GoLiveButton;
