import React from "react";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider, createTheme } from "@mui/material/styles";
import {theme} from "./layout/stylesheet";
import reportWebVitals from "./reportWebVitals";
import store from "./app/store";
import App from "./App";
import "./index.css";
import {CssBaseline, GlobalStyles} from "@mui/material";



const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>

                <ThemeProvider theme={theme}>
                    <GlobalStyles
                        styles={{
                            body: { backgroundColor: theme.palette.primary.dark}
                        }}
                    />
                    {/*<CssBaseline/>*/}
                    <App/>
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
