import {createTheme} from '@mui/material/styles'
import breakpoints from '../@core/theme/breakpoints'
import overrides from '../@core/theme/overrides'
import palette from '../@core/theme/palette'
import typography from '../@core/theme/typography'
import {getDesignTokens} from '../@core/theme/darkTheme/customDark';
import button from "../@core/theme/overrides/button";


// ** Merged ThemeOptions of Core
export let theme = createTheme

    (getDesignTokens('dark'))

// typography: {
//     fontFamily: ['Muli', 'serif', 'Roboto'].join(','),
// },


// ** Continue theme creation and pass merged component overrides to CreateTheme function
theme = createTheme(theme, {
    components: {...overrides(theme)},
    // typography: { ...typography(theme) },
})