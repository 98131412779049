export function getTimeDate(){
    const today = new Date();
    const dateObj = new Date();
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const min = today.getMinutes();
    const hours = today.getHours();
    const formattedDate = `${year}-${month}-${day}`;
    const formated_time = `${hours}:${min}`.split(":")[0].length<2?`0${hours}:${min}`:`${hours}:${min}`;

    return {date:formattedDate,time:formated_time,dateObj:today};
}