import React from 'react'
import {Grid, Toolbar} from '@mui/material'
import TitleBar from '../../components/TitleBar'
import ButtonBaseDemo from "./QuickActionButton";
import {useGetUserByEmailQuery} from "../../api/userApi";
import {useSelector} from "react-redux";
import Progress from "../../components/Progress";
import ScheduleLive from '../schedule-live/ScheduleLive';
import ScheduleLiveList from '../schedule-live/ScheduleLiveList';
import MultiChannelScheduling from '../../components/MultiChannelScheduling';
import IOSToggle from '../../components/IOSToggle';

export default function Home() {
    const {userEmail} = useSelector((state: any) => state.auth)
    const {data: user, isLoading} = useGetUserByEmailQuery(userEmail!)
    // console.log(`User Data Received : ${JSON.stringify(user)}`)

    if (isLoading) {
        return <Progress/>
    }

    return (
        <>
            <Grid container spacing={2}>
                <TitleBar title={user?.name ? `Welcome ${user.name}` : 'Welcome'} />

                <Toolbar/>
                
                
                <Grid item xs={12}>
                    <ButtonBaseDemo/>
                </Grid>
            </Grid>
           
            
            
        </>
    )
}
