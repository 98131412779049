import {
    Container,
    Box,
    BottomNavigation,
    BottomNavigationAction,
} from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import CancelIcon from "@mui/icons-material/Cancel";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import VideoPlay from "./VideoPlayTopText";

export default function LivePlayerST() {
    const videoUrl =
        "https://dsqqu7oxq6o1v.cloudfront.net/motion-array-1283899-vyaauR0o7J-high.mp4";
    const multilineText = `Stream health - Good\n
    Video Incoming - Yes\n
    Resolution - 1920*1080\n
    frame rare - 50fps\n
    Band width 6,21 mblt/s\n
    Views - 10K`;

    return (
        <div>
            <Container fixed sx={{margin: "auto"}}>
                <VideoPlay videoUrl={videoUrl} text={multilineText}/>
                <Box>
                    <BottomNavigation showLabels>
                        <BottomNavigationAction label="Start" icon={<PlayCircleFilledIcon/>}/>
                        <BottomNavigationAction label="End" icon={<CancelIcon/>}/>
                        <BottomNavigationAction label="Be Back" icon={<PauseCircleIcon/>}/>
                        <BottomNavigationAction label="Intermision" icon={<AvTimerIcon/>}/>
                    </BottomNavigation>
                </Box>
            </Container>
        </div>
    );
}
