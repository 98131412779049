import * as React from 'react'
import AdbIcon from '@mui/icons-material/Adb'
import SearchIcon from '@mui/icons-material/Search'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Menu from '@mui/material/Menu'
import {alpha, styled} from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import logo from '../assets/logo/logo.png'
import {Link} from "react-router-dom";
import {useLogoutMutation} from '../api/authApi'
import {MenuItem, Typography, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom'
import {theme} from "../layout/stylesheet";
import { useDispatch } from 'react-redux'
import { logoutSuccess } from '../api/slices/authSlice'


function ResponsiveAppBar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [logout] = useLogoutMutation()

    const onLogoutHandler = async () => {
        try {
            const result: any = await logout('')
            console.log(`Logout Result : ${JSON.stringify(result)}`)
            dispatch(logoutSuccess());
            window.location.reload()
            
        
        } catch (error: any) {
            console.log('Login error:', error)
        }
    }

    const Search = styled('div')(({theme}) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }))

    const SearchIconWrapper = styled('div')(({theme}) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }))

    const StyledInputBase = styled(InputBase)(({theme}) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },
    }))


    // const theme = useTheme();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    )

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <AppBar
            position="fixed"
            elevation={0}
            sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
            style={{background: theme.palette.background.default}}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to="/home" style={{textDecoration: "none"}}>
                        <Toolbar disableGutters>
                            <img
                                src={logo}
                                alt="log"
                                style={{paddingTop: 10, paddingBottom: 10, paddingLeft:10,height:50}}
                            />
                        </Toolbar>
                    </Link>
                    {/*<AdbIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>*/}
                    {/*<Typography*/}
                    {/*    variant="h5"*/}
                    {/*    noWrap*/}
                    {/*    component="a"*/}
                    {/*    href=""*/}
                    {/*    sx={{*/}
                    {/*        mr: 2,*/}
                    {/*        display: {xs: 'flex', md: 'none'},*/}
                    {/*        flexGrow: 1,*/}
                    {/*        fontFamily: 'monospace',*/}
                    {/*        fontWeight: 700,*/}
                    {/*        letterSpacing: '.3rem',*/}
                    {/*        color: 'inherit',*/}
                    {/*        textDecoration: 'none',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    LOGO*/}
                    {/*</Typography>*/}
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {/*<Search>*/}
                        {/*    <SearchIconWrapper>*/}
                        {/*        <SearchIcon/>*/}
                        {/*    </SearchIconWrapper>*/}
                        {/*    <StyledInputBase sx={{width: '300px'}}*/}
                        {/*                     placeholder="Search…"*/}
                        {/*                     inputProps={{'aria-label': 'search'}}*/}
                        {/*    />*/}
                        {/*</Search>*/}
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar  sx={{ background: theme.palette.secondary.main }}
                                        src="/broken-image.jpg"/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Link to='/profile' style={{textDecoration: "none"}}>
                                    <Button variant="text">
                                        <Typography textAlign="center" sx={{color: "primary.main"}}>Profile</Typography>
                                    </Button>
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Button onClick={onLogoutHandler} variant="text">
                                    <Typography textAlign="center" sx={{color: "primary.main"}}>Logout</Typography>
                                </Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
export default ResponsiveAppBar
