import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface UserState {

    name: string
    address: string
    contactNumber: string

}

const initialUserState: UserState = {

    name: '',
    address: '',
    contactNumber: '',

}

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser(state, action: PayloadAction<UserState>) {
            const {name, contactNumber, address} = action.payload

            state.name = name
            state.contactNumber = contactNumber
            state.address = address
        },
    },
})

export const {setUser} = userSlice.actions

export default userSlice.reducer

export const selectUser = (state: { user: UserState }) => state.user
