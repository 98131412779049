import {Theme} from '@mui/material'

const Typography = (theme: Theme) => {
    return {
        MuiTypography: {
            styleOverrides: {
                fontFamily: ['Roboto'].join(','),
            },
        },
    }
}

export default Typography
