// ** Overrides Imports
import {Theme} from '@mui/material'

import MuiButton from './button'
import MuiTypography from './typography'

const Overrides = (theme: Theme) => {
    const button = MuiButton(theme)

    const typography = MuiTypography(theme)

    return Object.assign(button, typography)
}

export default Overrides
