import React, {useState} from "react";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import axios from 'axios';

interface AvatarBadgeProps {
    avatarSrc: string;
}

const ProfileAvatar: React.FC<AvatarBadgeProps> = ({avatarSrc}) => {
    const [selectedAvatar, setSelectedAvatar] = useState(() => {
        const savedAvatar = localStorage.getItem('profileAvatar');
        return savedAvatar ? savedAvatar : avatarSrc;
    });

    const id = '1';
    const type = 'files';
    const category = 'profileImage';
    const fileKey = 'man.jpg';

    const getPresignedUrl = async () => {
        try {
            const response = await axios.post("https://t4ax25ebo0.execute-api.us-east-1.amazonaws.com/DEV-80/media/presign", {
                key: fileKey,
                id,
                type,
                category,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status !== 200) {
                const {url, s3Uri} = response.data;

                console.log('Presigned URL:', url);
                console.log('Presigned URIs:', s3Uri);

                throw new Error('Failed to get presigned URL');
            }

            return response.data;
        } catch (error) {
            // @ts-ignore
            throw new Error(`Error getting presigned URL: ${error.message}`);
        }
    };

    const uploadAvatarToS3 = async (savedAvatar: string | null, id: string) => {
        if (!savedAvatar) {
            return;
        }
        const type = 'image/jpg'; // Assuming the file type is PNG, you can adjust this based on your use case

        try {
            // First get the presigned URL from the backend
            const presignedUrl = await getPresignedUrl();

            console.log('Presigned URL:', presignedUrl.url);
            console.log('Presigned URL:', savedAvatar);

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: presignedUrl.data.url,
                headers: {
                    'Content-Type': 'text/plain',
                    "Access-Control-Allow-Origin": '*'

                },
                data: savedAvatar
            };

            const response = await axios.request(config)


            /*const response = await axios.put(presignedUrl.data.url, savedAvatar, {
                // method: 'PUT',
                //             body: savedAvatar,
                //             headers: {
                //                 'Content-Type': type,
                //             },
                headers: {
                    'Content-Type': type,
                },
            });*/

            if (response.status !== 200) {
                throw new Error('Failed to upload avatar to S3');
            }

            console.log('Avatar uploaded successfully to S3');
        } catch (error) {
            // @ts-ignore
            console.error(`Error uploading avatar to S3: ${error.message}`);
        }
    };

    // const uploadAvatarToS3 = async (savedAvatar: string | null, id: string) => {
    //     if (!savedAvatar) {
    //         return;
    //     }
    //
    //     const type = 'image/png'; // Assuming the file type is PNG, you can adjust this based on your use case
    //
    //     try {
    //         //First get the presigned URL from the backend
    //
    //         const presignedUrl = await getPresignedUrl();
    //
    //         console.log('Presigned URL:', presignedUrl.url);
    //
    //
    //         const response = await fetch(presignedUrl.url, {
    //             method: 'PUT',
    //             body: savedAvatar,
    //             headers: {
    //                 'Content-Type': type,
    //             },
    //         });
    //
    //         if (!response.ok) {
    //
    //             throw new Error('Failed to upload avatar to S3');
    //         }
    //
    //         console.log('Avatar uploaded successfully to S3');
    //     } catch (error) {
    //         // console.error(`Error uploading avatar to S3: ${error.message}`);
    //     }
    // };


    const updateUserProfile = async (id: string, s3Uri: string) => {
        try {
            const response = await axios.post(`https://t4ax25ebo0.execute-api.us-east-1.amazonaws.com/DEV-80/user/update?id=1`, {
                s3Uri,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.data) {
                throw new Error('Failed to update user profile');
            }

            return response.data;
        } catch (error) {
            // @ts-ignore
            throw new Error(`Error updating user profile: ${error.message}`);
        }
    };
    const handleAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const savedAvatar = event.target.files?.[0];

        if (!savedAvatar) {
            return;
        }

        try {
            const reader = new FileReader();
            reader.readAsDataURL(savedAvatar);

            reader.onload = async () => {
                const base64Data = reader.result?.toString();
                if (!base64Data) {
                    return;
                }

                setSelectedAvatar(base64Data); // Update the selectedAvatar state with the new avatar image

                // Upload the avatar to S3
                await uploadAvatarToS3(base64Data, id);

                // Update user profile with S3 link
                const s3Uri = `https://tvs-public-files-dev-80.s3.amazonaws.com/${type}/${category}/${id}/man.jpg`; // Adjust the S3 bucket URL and path
                await updateUserProfile(id, s3Uri);

                // Update localStorage with the new avatar
                localStorage.setItem('profileAvatar', base64Data);
            };
        } catch (error) {
            //console.error(`Error handling avatar change: ${error.message}`);
        }
    };

    // const handleAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files && event.target.files[0]) {
    //         const file = event.target.files[0];
    //         try {
    //             // Upload the file to the backend API and get the S3 link
    //             const s3Uri = await uploadAvatarToS3(file);
    //             setSelectedAvatar(s3Uri);
    //             localStorage.setItem('profileAvatar', s3Uri);
    //
    //             // Update the user's profile in the database
    //             await updateUserProfile(id, s3Uri);
    //
    //         } catch (error) {
    //             console.error('Error uploading avatar:', error);
    //         }
    //     }
    // };


    return (
        <Badge
            overlap="circular"
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            // badgeContent={
            //     <IconButton color="primary" aria-label="change avatar"
            //                 style={{color: "white", width: 35, height: 40, boxShadow: `0 0 10px #fff`}}>
            //         <input
            //             accept="image/*"
            //             style={{display: 'none'}}
            //             id="icon-button-file"
            //             type="file"
            //             onChange={handleAvatarChange}
            //         />
            //         {/*<label htmlFor="icon-button-file">*/}
            //         {/*    <PhotoCameraOutlinedIcon/>*/}
            //         {/*</label>*/}
            //     </IconButton>
            // }
        >
            <Avatar src={selectedAvatar} sx={{ml:"15%", width: 200, height: 200}}/>
        </Badge>
    );
};

// @ts-ignore
export default ProfileAvatar;