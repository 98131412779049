import {Box, TextField} from "@mui/material";
import {useEffect, useImperativeHandle, useState, forwardRef} from "react";
import * as React from "react";
import Chip from "@mui/material/Chip";

type TagItemProps = {
    label: string;
    onDelete: (label: string) => void;
};

export type InputTagsRef = {
    handleResetTags: () => void;
};

export const getTags = (tags: string[]): string[] => {
    return tags;
};

const TagItem = ({label, onDelete}: TagItemProps) => {
    return (
        <Chip
            variant="outlined"
            key={label}
            label={label}
            style={{margin: 2}}
            onDelete={() => onDelete(label)}
        />
    );
};

const InputTags = forwardRef<InputTagsRef, { onTagsChange: (newTags: string[]) => void, initialTags: string[] }>(({onTagsChange, initialTags}, ref) => {

    const [tags, setTags] = useState<string[]>(initialTags);
    const [inputValue, setInputValue] = useState<string>("");
    const [isInputFocused, setInputFocused] = useState(false);

    useEffect(() => {
        setTags(initialTags); // Initialize tags using initialTags prop
    }, [initialTags]);

    const handleResetTags = () => {
        setTags(initialTags);
        setInputValue("");
        onTagsChange(initialTags);
    };

    useImperativeHandle(ref, () => ({
        handleResetTags: handleResetTags
    }));


    useEffect(() => {
        // Update the text field's height to show all tags without overflowing
        const inputField = document.getElementById("tags-input");
        if (inputField) {
            inputField.style.height = "auto"; // Reset the height to calculate the new height
            const inputFieldHeight = inputField.scrollHeight;
            inputField.style.height = `${inputFieldHeight}px`;
        }
    }, [tags]);

    const handleDeleteTag = (tagToDelete: string) => {
        setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            addTag();
        }
    };

    const addTag = () => {
        if (inputValue.trim() && !tags.includes(inputValue)) {
            const newTags = [...tags, inputValue.trim()];
            setTags(newTags);
            setInputValue("");
            onTagsChange(newTags);
        }
    };
    return (
        <Box sx={{flexGrow: 1}}>
            <TextField
                key={tags.length}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                fullWidth
                variant='outlined'
                label="Tags"
                margin="normal"
                placeholder={tags.length < 5 ? "Enter tags" : ""}
                InputProps={{
                    startAdornment: (
                        <Box sx={{margin: "1rem 0.2rem 1rem 0", display: "flex"}}>
                            {tags.map((tag, index) => (
                                <TagItem key={index} label={tag} onDelete={handleDeleteTag}/>
                            ))}
                        </Box>
                    ),
                }}

                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                InputLabelProps={{
                    shrink: isInputFocused,
                }}
            />
        </Box>
    );
});

export default InputTags;




