import dayjs, {Dayjs} from "dayjs";

export function timeToUTC(setting: { date: Dayjs; time: Dayjs }):string{

    const date = new Date();
    if(setting.time){
    date.setHours(parseInt(dayjs(setting.time).format('HH')));
    date.setMinutes(parseInt(dayjs(setting.time).format('mm')));
    }
    if(setting.date){
    date.setFullYear(parseInt(dayjs(setting.date).format('YYYY')),
        parseInt(dayjs(setting.date).format('MM')),
        parseInt(dayjs(setting.date).format('DD')));
    //set Time zone
    //date.toLocaleString('en-US',{timeZone:'Asia/Colombo'})
    }
    console.log("formated utc time",date.toUTCString());
    return date.toUTCString();
}