import React from 'react'
import {Box, Divider, Grid} from '@mui/material'
import TitleBar from '../components/TitleBar'

export default function Vod() {
    const [value, setValue] = React.useState('1')

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }
    const handleSelectedChipsChange = (selectedChips: string[]) => {
        // Do something with the selectedChips array, such as updating the state or performing an action.
        console.log('Selected Chips:', selectedChips)
    }

    return (
        <>
            <Grid container spacing={2}>
                <TitleBar title="Upload Video"/>
                <Grid item xs={12}>
                    <Box sx={{width: '100%', typography: 'body1'}}>
                        <p>test upload</p>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    test 3
                </Grid>
            </Grid>
        </>
    )
}
