import React from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";
import LivePlayerST from "../../components/live-preview/LivePlayerST";
import TitleBar from "../../components/TitleBar";
import RecordFooter from "../../components/go-live/RecordFooter";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#8c9cae" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

function LivePreviewNext() {
    return (
        <>
            <Grid container spacing={2}>
                <TitleBar title="Live Stream"/>
                <Grid item xs={12}>
                    <Box sx={{
                        pt: 2,
                        pl: 6,
                        pr: 6,
                        pb: 2,
                        flexGrow: 1
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Item>
                                    {/*<StreamAddOn/>*/}
                                </Item>
                            </Grid>
                            <Grid item xs={8} sx={{
                                pb: 15
                            }}>
                                <Item sx={{pt: 4}}>
                                    <LivePlayerST/>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                    <RecordFooter/>
                </Grid>

            </Grid>

        </>
    );
}

export default LivePreviewNext;
