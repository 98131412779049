import React, {useState} from 'react'
import Chip from '@mui/material/Chip'
import categoryItems from '../data/categories.json'

interface Props {
    onChange: (selectedChips: string[]) => void
}

const CategoryChips: React.FC<Props> = ({onChange}) => {
    const [selectedChips, setSelectedChips] = useState<string[]>([])

    const handleChipClick = (category: string) => {
        const updatedSelection = selectedChips.includes(category)
            ? selectedChips.filter((c) => c !== category)
            : [...selectedChips, category]

        setSelectedChips(updatedSelection)
        onChange(updatedSelection)
    }

    return (
        <div style={{maxHeight: '150px', overflowY: 'auto'}}>
            {categoryItems.categories.map((category) => (
                <Chip
                    key={category}
                    label={category}
                    color={selectedChips.includes(category) ? 'primary' : 'default'}
                    onClick={() => handleChipClick(category)}
                    style={{margin: 5}}
                />
            ))}
        </div>
    )
}
export default CategoryChips
