import React from "react";
import {Typography} from "@mui/material";

type VideoPlayProps = {
    videoUrl: string;
    text: string;
};

const VideoPlayTopText = ({videoUrl, text}: VideoPlayProps) => {

    return (
        <div style={{position: "relative"}}>
            <div>
                <video controls width="100%" style={{height: "auto"}}>
                    <source src={videoUrl} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
            <div
                style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "#ffffff",
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "5px",
                }}
            >
                {text.split("\n").map((line, index) => (
                    <Typography variant="subtitle2" key={index}>{line}</Typography>
                ))}
            </div>
        </div>
    );
};
export default VideoPlayTopText;
