import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface LoginSession {
    jwtToken: string
    payload: {
        sub: string
        'cognito:groups': string[]
        iss: string
        client_id: string
        origin_jti: string
        event_id: string
        token_use: string
        scope: string
        auth_time: number
        exp: number
        iat: number
        jti: string
        username: string
    }
}

interface refreshTypes {
    token: string;
}

interface signupSession {
    username:string
}

export type UserSession = {
    accessToken: LoginSession
    refreshToken:refreshTypes
    userEmail: string
}

type AuthState = {
    username:string
    userEmail: string
    accessToken: LoginSession | null
    refreshToken:refreshTypes | null
    error: string | null
    isLoading: boolean,
    isTokenExpired:boolean,
    isSigningUp: boolean
    signupError: string | null
    isLogging: boolean
    loggingError: string | null
}

const initialState: AuthState = {
    accessToken: null,
    refreshToken: null,
    error: null,
    isLoading: false,
    isTokenExpired: true,
    isSigningUp: false,
    signupError: null,
    isLogging: false,
    loggingError: null,
    userEmail: '',
    username:'',
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginRequest: (state) => {
            state.isLoading = true
            state.error = null
        },
        loginSuccess: (state, action: PayloadAction<UserSession>) => {
            state.isLoading = false
            state.isTokenExpired = false
            state.accessToken = action?.payload?.accessToken
            state.refreshToken = action?.payload?.refreshToken
            state.isLogging = true
            state.userEmail = action.payload.userEmail
            state.error = null
        },

        updateToken(state, action: PayloadAction<any>) {
            state.isLoading = false;
            state.isTokenExpired = false;
            if( state.accessToken) state.accessToken.jwtToken = action?.payload?.accessToken;
            state.isLogging = true;
            state.error = null;
        },
        loginFailure: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        },
        logoutSuccess: (state) => {
            state.accessToken = null
            state.refreshToken = null
            state.isTokenExpired = true;
            state.isLogging = false;
            state.error = null
            localStorage.clear()
        },
        signupRequest: (state) => {
            state.isSigningUp = true
            state.signupError = null
        },
        signupSuccess: (state, action: PayloadAction<signupSession>) => {
            state.isSigningUp = false
            state.signupError = null
            state.username = action?.payload?.username
        },
        signupFailure: (state, action: PayloadAction<string>) => {
            state.isSigningUp = false
            state.signupError = action.payload
        },
        setTokenExpired: (state, action: PayloadAction<any>) => {
            state.isTokenExpired = action?.payload?.isExpired;
        }


    },
})

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
    logoutSuccess,
    signupRequest,
    signupSuccess,
    signupFailure,
    setTokenExpired,
    updateToken
} = authSlice.actions

export default authSlice.reducer

export const selectAccessToken = (state: { auth: AuthState }) => state.auth

export const selectError = (state: { auth: AuthState }) => state.auth.error

export const selectIsLoading = (state: { auth: AuthState }) =>
    state.auth.isLoading

export const selectIsLogin = (state: { auth: AuthState }) =>
    state.auth.isLogging
