import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {API_PATHS} from "../configs/api";
import {Event} from "../types/event";
import store from "../app/store";

function getUserIDFromState(): string {
    const reduxState = store.getState();
    const userID = reduxState.auth.accessToken?.payload.username;
    return userID || '';
}
export const eventApi = createApi({
    reducerPath: "eventApi",
    baseQuery: async (arg, api, extraOptions) => {
        const result = await fetchBaseQuery({
            baseUrl: API_PATHS.BASE_URL,
            prepareHeaders: (headers, {getState}) => {
                const accessToken = (getState() as any).auth.accessToken;
                if (accessToken && accessToken.jwtToken) {
                    headers.set("Authorization", `Bearer ${accessToken.jwtToken}`);
                }
                return headers;
            },
        })(arg, api, extraOptions)
        if ('error' in result) {
            let status = 'FETCH_ERROR';
            if (result?.error?.status) {
                status = result.error.status.toString();
            } else if (result.meta && result.meta.response && result.meta.response.status) {
                status = result.meta.response.status.toString();
            }
            console.log(status);
            return {
                error: { ...result.error, status },
            
            }
        }
        return result
    },
    endpoints: (builder) => ({
        createEvent: builder.mutation<CreateEventResponse, CreateEventRequest>({
            query: (credentials) => ({
                url: `${API_PATHS.EVENT.CREATE}`,
                method: "POST",
                body: credentials,
            }),
            async onQueryStarted({title, description, tags, startDate, startTime, facebookStreamKey, youtubeStreamKey, twitchStreamKey,userID }, {queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data);
                } catch (error) {
                    console.log(`create event error`);
                }
            },
        }),
        getEventList: builder.query<Event[], void>({
            query: () => ({
                url: `${API_PATHS.EVENT.LIST}?userID=${getUserIDFromState()}`,
            }),
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data);
                } catch (error) {
                    console.error(`Event list error`,error);
                }
            }
        }),
        updateEventById: builder.mutation<UpdateResponse, UpdateRequest>({
            query: ({id, body}) => ({
                url: `${API_PATHS.EVENT.UPDATE}?id=${id}`,
                method: "PUT",
                body: body,
            }),
            async onQueryStarted({body}, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data);
                } catch (error) {
                    console.log(`Event update error`);
                }
            },
        }),
        deleteEventById: builder.mutation<void, DeleteEventPayload>({
            query: ({eventId,id}) => ({
                url: `${API_PATHS.EVENT.DELETE}?id=${eventId}&eventid=${id}`,
                method: "DELETE",
            }),
        }),

    }),
});

export const {
    useGetEventListQuery,
    useDeleteEventByIdMutation,
    useUpdateEventByIdMutation,
    useCreateEventMutation,

} = eventApi;

interface DeleteEventPayload {
    eventId: string;
    id: string;
}


interface UpdateRequest {
    id: string;
    body: {
        title: string;
        description: string;
        startDate: string;
        startTime: string;
        facebookStreamKey:string;
        youtubeStreamKey:string;
        twitchStreamKey:string;
        tags: string[];

    };
}

interface UpdateResponse {
    title: string;
    description: string;
    startTime: string;
    facebookStreamKey:string;
    youtubeStreamKey:string;
    twitchStreamKey:string;
    tags: string[];
    userID:string;

}

interface CreateEventRequest {
    title: string
    description: string
    tags: string[]
    startDate: string | null
    startTime: string | null
    facebookStreamKey:string
    youtubeStreamKey:string
    twitchStreamKey:string
    userID:string
}

interface CreateEventResponse {
    title: string
    description: string
    tags: string[]
    startDate: string
    startTime: string
    facebookStreamKey:string
    youtubeStreamKey:string
    twitchStreamKey:string
    userID:string
}
