import { useLocation, useNavigate, Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useEffect, useState } from "react";
import { logoutSuccess, setTokenExpired } from "../../api/slices/authSlice";
import { jwtDecode } from "jwt-decode";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { theme } from "../../layout/stylesheet";
import { useRefreshMutation } from "../../api/authApi";

export type KeyedObject = {
  [key: string]: string | number | KeyedObject | any;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  try {
    const decoded: KeyedObject = jwtDecode(serviceToken);
    // console.log("serviceToken",serviceToken);
    // console.log("Decoded",decoded);
    //console.log(decoded.exp > Date.now() / 1000);
    const expirationDate = new Date(decoded.exp * 1000);
    // console.log(expirationDate);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const PrivateRoutes = (props: any) => {
  const [refresh] = useRefreshMutation();
 

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isLogin = useSelector((state: RootState) => state.auth.isLogging);
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const refreshToken = useSelector(
    (state: RootState) => state.auth.refreshToken
  )
  const isTokenExpired = useSelector(
    (state: RootState) => state.auth.isTokenExpired
  );


  async function RefreshToken() {
    setIsRefreshing(true);  
    // console.log("Refresh Token....",refreshToken)
    await refresh({refreshToken:refreshToken ? refreshToken?.token : ""});
    setIsRefreshing(false);
  }
  function logout() {
    dispatch(logoutSuccess());
    localStorage.clear();
    setIsLoading(false);
    navigate("/", {
      state: {
        from: location.pathname,
      },
      replace: true,
    });
  }

  useEffect(() => {
    setIsLoading(true);
    if (!(accessToken && isLogin && verifyToken(accessToken.jwtToken))) {
      dispatch(setTokenExpired({ isExpired: true }));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    // console.log( "IS TOKEN",isTokenExpired);
    if(!isLogin && isTokenExpired){
      // console.log("Redirecting to login");
      navigate("/", {
        state: {
          from: location.pathname,
        },
        replace: true,
      });
    }


  }, [isLogin, location]);

  return isLogin && !isLoading ? (
    <>
      <Dialog
        open={isTokenExpired}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to stay sign in?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <p>
              Your session is about to expire. Please press the "Keep me signed
              in" button to stay logged in.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout}>No,Sign me out</Button>
          <Button
            sx={{
              background: theme.palette.secondary.main,
              color: theme.palette.text.primary,
            }}
            onClick={async () => {
              RefreshToken();
            }}
          >
         { isRefreshing ?  "Loading..." : "Yes,Keep me sign in"}
          </Button>
        </DialogActions>
      </Dialog>{" "}

      <Outlet />{" "}
    </>
  ) : (
    <>
    
    </>
  );
};
export default PrivateRoutes;
