import React, {ReactNode} from 'react'
import {Box, Container} from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import {theme} from "../layout/stylesheet";


interface TitleBarProps { 
    title: string
    buttons?: ReactNode
}

const TitleBar: React.FC<TitleBarProps> = ({title, buttons}) => {
    return (
        <>
            <Container
                disableGutters
                maxWidth={false}
                sx={{
                    bgcolor: 'tertiary.main',
                    width: 1,
                    pt: 8
                }}
            >
                <Box sx={{
                    pt: 6,
                    pl: 6,
                    pr: 6,
                    pb: 2,
                    flexDirection: 'row',
                    flexGrow: '1',
                    display: 'flex'
                }}>

                    <Toolbar
                        sx={{
                            display: 'flex',
                            flexGrow: 1// Add flexGrow: 1 to make the Toolbar take up the available space
                        }}
                    >
                        <Typography
                            variant="h2"
                            component="div"
                            fontWeight="bold"
                            sx={{flexGrow: 1, color: [theme.palette.secondary.main]}}
                        >
                            {title}
                        </Typography>
                        {buttons && buttons}
                    </Toolbar>

                </Box>
            </Container>
        </>
    )
}

export default TitleBar
