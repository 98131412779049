import {Outlet, Route, Routes} from "react-router-dom";
import Appbar from "./components/Appbar";
import Sidebar from "./components/Sidebar";
import Home from "./pages/home/Home";
import ForgotPassword from "./pages/login-and-registration/ForgotPassword";
import VerificationCode from "./pages/login-and-registration/VerificationCode";
import LoginLayout from "./pages/login-and-registration/LoginLayout";
import NewPassword from "./pages/login-and-registration/NewPassword";
import RegisterLayout from "./pages/login-and-registration/RegisterLayout";
import TermsAndConditions from "./pages/login-and-registration/TermsAndConditions";
import Vod from "./pages/vod/Vod";
import ScheduleLive from "./pages/schedule-live/ScheduleLive";
import ScheduleLiveList from "./pages/schedule-live/ScheduleLiveList";
import "./App.css";
import LivePreview from "./pages/go-live/LivePreview";
import LivePreviewNext from "./pages/go-live/LivePreviewNext";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/EditProfile";
import VideoPage from "./pages/vod/VideoPage";
import UploadVideo from "./pages/UploadVideo";
import PrivateRoutes from "./components/protected-routes/PrivateRoutes";

function App(props: any) {
    const SidebarLayout = () => (
        <>
            <Sidebar/>
            <Appbar/>
            <Outlet/>
        </>
    );

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<LoginLayout/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/register" element={<RegisterLayout/>}/>
                <Route path="/account-verification" element={<VerificationCode/>}/>
                <Route path="/new-password" element={<NewPassword/>}/>
                {/*<Route path="/reset-password" element={<ResetPassword/>}/>*/}
                <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                <Route path="/register" element={<RegisterLayout/>}/>

                <Route element={<PrivateRoutes/>}>
                    <Route element={<SidebarLayout/>}>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/vod" element={<Vod/>}/>
                        <Route path="/vod/video/:id" element={<VideoPage/>}/>
                        <Route path="/upload-video" element={<UploadVideo/>}/>
                        <Route path="/schedule-live" element={<ScheduleLive/>}/>
                        <Route path="/scheduled-live-list" element={<ScheduleLiveList/>}/>
                        <Route path="/go-live" element={<LivePreview/>}/>
                        <Route path="/live-stream" element={<LivePreviewNext/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/EditProfile" element={<EditProfile/>}/>
                    </Route>
                </Route>
            </Routes>

        </div>
    );
}

export default App;
