import React, {useState} from 'react'
import {Box, Button, Grid} from '@mui/material'
import HighlightCard from '../components/card/HighlightCard'
import videoItems from '../data/videoItems.json'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function DisplayHighlightVideos() {
    const [visible, setVisible] = useState(4)
    const canLoadMore = videoItems.length > visible
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4)
    }

    return (
        <>
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <Grid
                    container
                    spacing={2}
                    xs={12}
                    sx={{justifyContent: 'flex-start', rowGap: 5, columnGap: 5}}
                >
                    {videoItems.slice(0, visible).map((item) => (
                        <Grid item key={item.id}>
                            <HighlightCard {...item} />
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{justifyContent: 'flex-start', mt: 5}}>
                    {canLoadMore && <Button onClick={showMoreItems}>Load more <KeyboardArrowRightIcon/></Button>}
                </Box>
            </Box>
        </>
    )
}
