import React, {useEffect, useRef} from 'react';
import {
    Button,
    TextField,
    Grid, FormControl, Backdrop,
} from '@mui/material';
import BasicDatePicker from '../../components/BasicDataPicker';
import BasicTimePicker from '../../components/BasicTimePicker';
import InputTags from '../../components/InputTags';
import {InputTagsRef} from "../../components/InputTags";
import TitleBar from '../../components/TitleBar';
import Box from '@mui/material/Box';
import {useState} from 'react';
import {Link} from "react-router-dom";
import SaveResetButtonGroup from "../../components/SaveResetButtonGroup";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import dayjs, {Dayjs} from 'dayjs';
import {useCreateEventMutation, useGetEventListQuery} from "../../api/eventApi";
import {useCreateEndPointMutation, useCreatePackageChannelMutation} from "../../api/mediaPackageApi";
import {useCreateLiveInputMutation} from "../../api/mediaLiveApi";
import {v4 as uuidv4} from 'uuid';
import Progress from "../../components/Progress";
import {timeToUTC} from "../../utils/timeToUTC";
import {getTimeDate} from "../../utils/getTimeDate";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MultiStreamOutputs from "../../components/MultiStreamOutputs";
import {useNavigate} from 'react-router-dom';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import store from "../../app/store";
import Tooltip from "@mui/material/Tooltip";
import {sortEvents} from "../../utils/sortEvents";
import {theme} from "../../layout/stylesheet";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SystemAlert from "../../components/SystemAlert";

type ScheduleDataTypes = {
    title: string;
    description: string;
    tags: string[];
    startDate: string | null;
    startTime: string | null;
    facebookStreamKey:string
    youtubeStreamKey:string
    twitchStreamKey:string
    userID:string

};

const ScheduleLive: React.FC = () => {

    const reduxState = store.getState()
    console.log(reduxState)
    // console.log(reduxState.auth.accessToken?.payload);
    // const userID = reduxState.auth.accessToken?.payload.username;

    const navigate = useNavigate();
    const [createEvent] = useCreateEventMutation();
    const [createInput] = useCreateLiveInputMutation();
    const [createMediaPackageChannel] = useCreatePackageChannelMutation();
    const [createMediaPackageEndPoint] = useCreateEndPointMutation();

    const [showStreamAddOn, setShowStreamAddOn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [disblepast, setDisblepast] = useState(true);
    const [isTimePickerEnable, setTimePickerEnable] = useState(true);

    const [isFacebookSwitchOn, setIsFacebookSwitchOn] = useState(false);

    const [isYoutubeSwitchOn, setIsYoutubeSwitchOn] = useState(false);
    const [isTwitchSwitchOn, setIsTwitchSwitchOn] = useState(false);

    const [scheduleData, setScheduleData] = useState<ScheduleDataTypes>({
        title: '',
        description: '',
        tags: [],
        startDate: null,
        startTime: null,
        facebookStreamKey:'',
        youtubeStreamKey:'',
        twitchStreamKey:'',
        userID:`${reduxState.auth.accessToken?.payload.username}`,
    });

    const [showWarning,setShowWarning] = useState('none');


    console.log(scheduleData.userID);
    const inputTagsRef = useRef<InputTagsRef | null>(null);

    const handleTagsChange = (newTags: string[]) => {
        setScheduleData((prevScheduleData) => ({
            ...prevScheduleData,
            tags: newTags,
        }));
    };

    const handleDatePickerChange = (startDate: Dayjs | null) => {
        //console.log("Start Date",startDate);
        setTimePickerEnable(false);
        if (startDate) {
            if (getTimeDate().date !== startDate.format('YYYY-MM-DD')) {
                setDisblepast(false);

            } else {
                setDisblepast(true);
            }
        }

        setScheduleData((prevScheduleData) => ({
            ...prevScheduleData,
            startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
        }));
    };

    const handleTimePickerChange = (selectedTime: Dayjs | null) => {
        if (selectedTime) {
            const prevScheduleData = {
                ...scheduleData,
                startTime: timeToUTC({'date': dayjs(scheduleData.startDate), 'time': selectedTime}),
            };
            setScheduleData(prevScheduleData);
            console.log("sLive__format time", timeToUTC({'date': dayjs(scheduleData.startDate), 'time': selectedTime}))
        }
    };

    const handleInputChange = (field: keyof ScheduleDataTypes) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setScheduleData((prevScheduleData) => ({
            ...prevScheduleData,
            [field]: event.target.value,
        }));
    };

    const handleFacebookInputChange = (value: string) => {
        setScheduleData((prevScheduleData) => ({
            ...prevScheduleData,
            facebookStreamKey: value,
        }));
    };

    const handleYoutubeInputChange = (value: string) => {
        setScheduleData((prevScheduleData) => ({
            ...prevScheduleData,
            youtubeStreamKey: value,
        }));
    };

    const handleTwitchInputChange = (value: string) => {
        setScheduleData((prevScheduleData) => ({
            ...prevScheduleData,
            twitchStreamKey: value,
        }));
    };

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const [error, setError] = useState('');


    const handleButtonClick = async () => {
        console.log('Button clicked!');

        function showToast(message: string): void {
            toast.warning(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

        if (!scheduleData.title) {
            showToast('Please Enter a Title for Live stream');
            return;
        }
        if (!scheduleData.description) {
            showToast('Please Enter a Description for Live stream');
            return;
        }
        if (!scheduleData.startDate) {
            showToast('Please select a Date');
            return;
        }
        if (!scheduleData.startTime) {
            showToast('Please select a Time');
            return;
        }
        if (isFacebookSwitchOn && !scheduleData.facebookStreamKey) {
            showToast('Please Enter a Facebook Stream Key');
            return;
        }
        if (isYoutubeSwitchOn && !scheduleData.youtubeStreamKey) {
            showToast('Please Enter a Youtube Stream Key');
            return;
        }
        if (isTwitchSwitchOn && !scheduleData.twitchStreamKey) {
            showToast('Please Enter a Twitch Stream Key');
            return;
        }


        const currentDate = new Date(getTimeDate().date);
        const selectedDate = new Date(scheduleData.startDate);
        const selectedTime = dayjs(scheduleData.startTime).format('HH:MM');

        if (currentDate.getTime() > selectedDate.getTime() ||
            (currentDate.getTime() === selectedDate.getTime() && selectedTime < getTimeDate().time)) {
            showToast('Allow only times in the future');
            return;
        }

        setError('');
        console.log("formated time_________", scheduleData.startTime);

        try {
            setIsLoading(true);
            const requestData = {
                title: scheduleData.title,
                description: scheduleData.description,
                tags: scheduleData.tags,
                startDate: scheduleData.startDate,
                startTime: scheduleData.startTime,
                facebookStreamKey:scheduleData.facebookStreamKey,
                youtubeStreamKey:scheduleData.youtubeStreamKey,
                twitchStreamKey:scheduleData.twitchStreamKey,
                userID: scheduleData.userID,
            };
            const result: any = await createEvent(requestData);
            console.log(requestData);
            const uniqueStreamKey = uuidv4();
            await createInput({
                eventId: result.data.id,
                inputName: `${result.data.id}_${requestData.userID}`,
                streamName: `Streameg-${uniqueStreamKey}`,
            });
            console.log('dsdsd',requestData.userID);
            const uniqueChannelId = uuidv4();
            await createMediaPackageChannel({eventId: result.data.id, Id: uniqueChannelId, userId: requestData.userID});
            await createMediaPackageEndPoint({eventId: result.data.id, channelId: uniqueChannelId});

            // showToast('Scheduled successfully');

            navigate('/scheduled-live-list')

        } catch (error) {
            console.log('Error:', error);
            setIsLoading(false);
        }
    };

    const handleResetClick = () => {
        setScheduleData({
            title: '',
            description: '',
            tags: [],
            startDate: null,
            startTime: null,
            facebookStreamKey:'',
            youtubeStreamKey:'',
            twitchStreamKey:'',
            userID:''
        });
       //window.location.href="/schedule-live";

    };

    const handleMouseEnter = () => {
        setShowStreamAddOn(true);
    };

    const handleMouseLeave = () => {
        setShowStreamAddOn(false);
    };

    const [previewImage, setPreviewImage] = useState<string | null>(null);

    const handleViewClick = (imageName: string) => {
        setPreviewImage(imageName);
    };

    const {data: eventList, refetch} = useGetEventListQuery();
    // const channelID = eventList?;
    console.log("Data is", eventList);


    const eventNID = eventList?.[0]?.event?.id;
    console.log("event Data is", eventNID);


    useEffect(() => {
        if(eventList && eventList.length > 0){
            setShowWarning('flex');
        }
        else{
            setShowWarning('none');
        }

        refetch();

    }, [refetch]);



    if (isLoading) {
        return <Progress/>;
    }

    return (
        <> <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
            <Grid container spacing={2} >
                <SystemAlert title={'Upgrade today for unlimited possibilities!'} msg={'Trial users can create one channel at a time. Elevate your experience with the Premium plan and create multiple channels simultaneously for enhanced capabilities and unbeatable flexibility.'} display={showWarning} color={'#0d3c8b'} icontype={'info'}/>
                <TitleBar title="Schedule Live"/>
                <Grid item xs={12}>
                    <Box sx={{
                        pt: (eventList && eventList.length > 0)? 0:2,
                        pl: 6,
                        pr: 6,
                        pb: 6,
                        mb: 2,
                    }}>

                        <Box sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mb: 2,
                            mr:2.5
                        }}>
                            <Link to="/scheduled-live-list">
                                <Button variant='outlined' sx={{
                                    textTransform:'capitalize',
                                    color:theme.palette.secondary.main,
                                     borderColor:theme.palette.secondary.main,
                                    }}>
                                    Scheduled Live List
                                    <KeyboardArrowRightIcon/>
                                </Button>
                            </Link>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}>

                            <Grid item xs={7} sx={{mr: 2}}>
                                <FormControl fullWidth>

                                    <TextField
                                        required
                                        id="outlined-basic"
                                        label="Live Stream Title"
                                        value={scheduleData.title}
                                        onChange={handleInputChange('title')}
                                        sx={{mb: 1}}
                                    />
                                    <TextField
                                        required
                                        label="Description"
                                        id="outlined-multiline-flexible"
                                        multiline
                                        minRows={2}
                                        maxRows={4}
                                        margin="normal"
                                        value={scheduleData.description} onChange={handleInputChange('description')}
                                        sx={{mt:3}}
                                    />

                                    <InputTags ref={inputTagsRef} onTagsChange={handleTagsChange}
                                               initialTags={scheduleData.tags}/>

                                    <Box sx={{display: "flex", gap: 4, mt: 1, mb: 2}}>
                                        <Box sx={{flex: 1, flexBasis: "50%"}}>

                                            <BasicDatePicker onDateChange={handleDatePickerChange}
                                                             defaultValue={null}/>
                                        </Box>
                                        <Box sx={{flex: 1, flexBasis: "50%"}}>
                                                <BasicTimePicker onTimeChange={handleTimePickerChange}
                                                                 isDisablePast={disblepast}
                                                                 isDisable={isTimePickerEnable}
                                                                 // defaultValue={dayjs(getTimeDate().dateObj.setTime(getTimeDate().dateObj.getTime() + 1000 * 60 * 5))}
                                                                 defaultValue={null}
                                                />
                                        </Box>
                                    </Box>
                                </FormControl>

                            </Grid>

                            <Grid item xs={5} sx={{mr: 2}}>
                                <MultiStreamOutputs
                                    isFacebookSwitchOn={isFacebookSwitchOn}
                                    setIsFacebookSwitchOn={setIsFacebookSwitchOn}
                                    onFacebookInputChange={handleFacebookInputChange}

                                    isYoutubeSwitchOn={isYoutubeSwitchOn}
                                    setIsYoutubeSwitchOn={setIsYoutubeSwitchOn}
                                    onYoutubeInputChange={handleYoutubeInputChange}

                                    isTwitchSwitchOn={isTwitchSwitchOn}
                                    setIsTwitchSwitchOn={setIsTwitchSwitchOn}
                                    onTwitchInputChange={handleTwitchInputChange}
                                />
                            </Grid>


                            <Box/>
                        </Box>


                        <Box sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mr: 2,
                            mb: 2
                        }}>
                            <Box sx={{textAlign: "right", justifyContent: "flex-end"}}>
                                <SaveResetButtonGroup eventNID={eventNID} onReset={handleResetClick} onClick={handleButtonClick}/>
                            </Box>
                        </Box>


                    </Box>

                </Grid>
            </Grid>
            {error &&
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <MuiAlert elevation={10} variant="filled" onClose={handleSnackbarClose} severity="warning">
                        {error}
                    </MuiAlert>
                </Snackbar>
            }
        </>
    );
};

export default ScheduleLive;

