import React from 'react'
import {
    Box,
    ButtonBase,
    Card,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material'

type VideoCardProps = {
    id: number
    title: string
    subTitle: string
    thumbnailUrl: string
    videoUrl: string
    createDate: string
    onCardClick: (videoUrl: string, title: string, subTitle: string) => void
}

const VideoPreviewCard: React.FC<VideoCardProps> = ({
                                                        id,
                                                        title,
                                                        subTitle,
                                                        thumbnailUrl,
                                                        videoUrl,
                                                        createDate,
                                                        onCardClick,
                                                    }) => {
    const handleClick = () => {
        // Call the onCardClick handler and pass the necessary properties
        onCardClick(videoUrl, title, subTitle)
    }

    return (
        <ButtonBase sx={{borderRadius: '20px'}} onClick={handleClick}>
            <Card
                sx={{maxWidth: 300, maxHeight: 350, borderRadius: '20px'}}
                elevation={3}
            >
                <CardMedia
                    sx={{height: '230px', width: '250px'}}
                    image={thumbnailUrl}
                />
                <CardContent>
                    <Box sx={{maxWidth: 200}}>
                        <Typography noWrap gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>
                        <Typography noWrap variant="body2" color="text.secondary">
                            {subTitle}
                        </Typography>
                    </Box>
                    <Box sx={{textAlign: 'end'}}>
                        <Typography noWrap variant="body2" color="text.secondary">
                            {createDate}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </ButtonBase>
    )
}

export default VideoPreviewCard
