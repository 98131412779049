import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { theme } from '../layout/stylesheet';
import FacebookOriginal from '../assets/images/FacebookOriginal.png'
import FacebookPurple from '../assets/images/FacebookPurple.png';
import YoutubOriginal from '../assets/images/YoutubeOriginal.png';
import YoutubePurple from '../assets/images/YoutubePurple.png';
import TwitchOriginal from '../assets/images/TwitchOriginal.png';
import TwitchPurple from '../assets/images/TwitchPurple.png';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IOSToggle from './IOSToggle';
import { ButtonBase, IconButton } from '@mui/material';
import { Interface } from 'readline';

interface props {
  facebookStreamKey: string;
  youtubeStreamKey: string;
  twitchStreamKey: string;
  onFacebookStreamKeyChange: (value: string) => void;
  onYoutubeStreamKeyChange: (value: string) => void;
  onTwitchStreamKeyChange: (value: string) => void;
  onChannel1ToggleChange: (value: boolean) => void;
  onChannel2ToggleChange: (value: boolean) => void;
  onChannel3ToggleChange: (value: boolean) => void;
  isDisabled?: boolean;
}


export default function MultiChannelScheduling(prop:props) {
  const [toggle1, setToggle1] = useState<boolean>(false);
  const [toggle2, setToggle2] = useState<boolean>(false);
  const [toggle3, setToggle3] = useState<boolean>(false);
  const [anyToggleOpen, setAnyToggleOpen] = useState<boolean>(false);

  const [facebookImage, setFacebookImage] = useState<string>(FacebookPurple);
  const [youtubeImage, setYoutubeImage] = useState<string>(YoutubePurple);
  const [twitchImage, setTwitchImage] = useState<string>(TwitchPurple);


  const [showPassword1, setShowPassword1] = useState<boolean>(false);
  const [showPassword2, setShowPassword2] = useState<boolean>(false);
  const [showPassword3, setShowPassword3] = useState<boolean>(false);



  useEffect(() => {
     
    if (prop.facebookStreamKey) {
      setToggle1(true);
      setFacebookImage(FacebookOriginal);
    }
    if (prop.youtubeStreamKey) {
      setToggle2(true);
      setYoutubeImage(YoutubOriginal);
    }
    if (prop.twitchStreamKey) {
      setToggle3(true);
      setTwitchImage(TwitchOriginal);
    }
  }, [prop.facebookStreamKey, prop.youtubeStreamKey, prop.twitchStreamKey]);



  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleClickShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };



  const handleRowClick = (toggleNumber: number, setImageFunction: Function) => () => {
    switch (toggleNumber) {
      case 1:
        setToggle1(!toggle1);
        setAnyToggleOpen(!toggle1 || toggle2 || toggle3);
        if (!toggle1) {
          setImageFunction(FacebookOriginal);
        } else {
          setImageFunction(FacebookPurple);
        }
        if (!toggle1) {
          prop.onChannel1ToggleChange(true); 
          
        } else {
          prop.onChannel1ToggleChange(false); 
          prop.onFacebookStreamKeyChange('');
        }
        break;
      case 2:
        setToggle2(!toggle2);
        setAnyToggleOpen(toggle1 || !toggle2 || toggle3);
        if (!toggle2) {
          setImageFunction(YoutubOriginal);
        } else {
          setImageFunction(YoutubePurple);
        }
        if (!toggle2) {
          prop.onChannel2ToggleChange(true); 
        } else {
          prop.onChannel2ToggleChange(false); 
          prop.onYoutubeStreamKeyChange('');
        }
        break;
      case 3:
        setToggle3(!toggle3);
        setAnyToggleOpen(toggle1 || toggle2 || !toggle3);
        if (!toggle3) {
          setImageFunction(TwitchOriginal);
        } else {
          setImageFunction(TwitchPurple);
        }
        if (!toggle3) {
          prop.onChannel3ToggleChange(true); 
        } else {
          prop.onChannel3ToggleChange(false); 
          prop.onTwitchStreamKeyChange('');
        }
        break;
      default:
        break;
    }
  };
  
  return (
    <Box
    sx={{
      width: '60%',
      maxWidth: '70%',
      boxSizing: 'border-box',
      '& .inner-box': {
      }
    }}
    >
      <Grid container spacing={2}>
      <ButtonBase
       disabled={prop.isDisabled}
        onClick={handleRowClick(1, setFacebookImage)}
        sx={{
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          my: 1,
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1}>
            <img src={facebookImage} width={'27px'} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="bodyLabel" sx={{ color: theme.palette.text.primary }}>Facebook</Typography>
          </Grid>
          <Grid item xs={5}>
            <IOSToggle open={toggle1 } disabled={prop.isDisabled} />
          </Grid>
        </Grid>
        {(toggle1) && (
          <Grid container spacing={1} alignItems="center" sx={{ my: 1 }}>
          <Grid item xs={9}>
            <TextField
              label="Facebook Stream Key"
              type={showPassword1 ? 'text' : 'password'}
              fullWidth
              autoComplete='off'
              value={prop.facebookStreamKey}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => prop.onFacebookStreamKeyChange(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword}
                      size="small" 
                    >
                      {showPassword1 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
           </Grid>
         </Grid>
        )}
      </ButtonBase>


      <ButtonBase
          disabled={prop.isDisabled}
          onClick={handleRowClick(2,setYoutubeImage)}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column', 
            alignItems: 'center',
            justifyContent: 'space-between',
            width:'100%',
          }}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <img src={youtubeImage} width={'30px'} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyLabel" sx={{ color: theme.palette.text.primary }}>Youtube</Typography>
            </Grid>
            <Grid item xs={5}>
            <IOSToggle open={toggle2} disabled={prop.isDisabled}  />
            </Grid>
          </Grid>
        {toggle2 && (
           <Grid container spacing={1} alignItems="center"sx={{my:1}}>
           <Grid item xs={9}>
           <TextField 
              label="Youtube Stream Key"
              type={showPassword2 ? 'text' : 'password'} 
              fullWidth 
              autoComplete='off'
              value={prop.youtubeStreamKey}
              onClick={(e) => e.stopPropagation()} 
              onChange={(e) => prop.onYoutubeStreamKeyChange(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword}
                      size="small"
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
           </Grid>
         </Grid>
        )}
      </ButtonBase>

      <ButtonBase
          disabled={prop.isDisabled}
          onClick={handleRowClick(3,setTwitchImage)}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column', 
            alignItems: 'center',
            justifyContent: 'space-between',
            width:'100%',
            my:1,
          }}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <img src={twitchImage} width={'30px'} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyLabel" sx={{ color: theme.palette.text.primary }}>Twitch</Typography>
            </Grid>
            <Grid item xs={5}>
            <IOSToggle open={toggle3} disabled={prop.isDisabled} />
            </Grid>
          </Grid>
        {toggle3 && (
           <Grid container spacing={1} alignItems="center" sx={{my:1}}>
           <Grid item xs={9}>
           <TextField 
              label="Twitch Stream Key"
              type={showPassword3 ? 'text' : 'password'} 
              fullWidth 
              autoComplete='off'
              value={prop.twitchStreamKey}
              onClick={(e) => e.stopPropagation()} 
              onChange={(e) => prop.onTwitchStreamKeyChange(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword3}
                      onMouseDown={handleMouseDownPassword}
                      size="small"
                    >
                      {showPassword3 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
           </Grid>
         </Grid>
        )}
      </ButtonBase>
      </Grid>
    </Box>
  );
}
