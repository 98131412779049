import {Box, Grid, Typography} from '@mui/material'
import Login from '../../components/Login'
import LoginLeftSideImage from "../../components/LoginLeftSideImage";
import {theme} from "../../layout/stylesheet";
import logo from "../../assets/logo/logo.png";
type Props = {}

const LoginLayout = (props: Props) => {

    return (
        <>
            <Box sx={{
                width: '100%',
                position:'relative',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height:'100vh'
            }}>
                <Box sx={{width: {sm:'90%',md:'90%',lg:'80%',xl:'75%'}, height: 'auto'}}>

                    <Grid container spacing={0}>
                        <Grid item xs={0} md={0} lg={4} xl={3} sx={{display:{xs:'none',sm:'none',lg:'flex'},
                        justifyContent:'right',
                        }}>
                           <LoginLeftSideImage/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={8} xl={9}>
                            <Box sx={{

                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: {xs:'46px',sm:'46px',md:'46px',lg:'46px 0px 0px 46px'},
                                position: 'relative',
                                height:'auto',
                                boxShadow:8,
                                minHeight:'80vh'
                            }}>
                                <Box sx={{pt:5, pl:5, pr:3}}>
                                    <Box sx={{display:{xs:'flex',sm:'flex',md:'flex',lg:'none',xl:'none'},justifyContent:'center'}}>
                                        <img src={logo} width={'80%'} style={{marginTop:'85px'}}/>
                                    </Box>

                                    <Typography variant="h3" sx={{color: theme.palette.primary.main
                                    ,textAlign:{xs:'center',sm:'center',md:'center',lg:'left',xl:'left'},
                                        fontSize:{xs:'2.4rem',sm:'2.4rem',md:'2.4rem',lg:'3rem',xl:'3rem'}
                                    }
                                    }>Sign
                                    in</Typography>
                                    <Typography sx={{color: theme.palette.primary.main,
                                        textAlign:{xs:'center',sm:'center',md:'center',lg:'left',xl:'left'}
                                    }}>
                                        Welcome to the Streameg admin panel, Please enter your login credentials below to start using the site
                                    </Typography></Box>
                                <Box sx={{mt:5}}>
                                    <Login/>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </>
    )
}

export default LoginLayout
