import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Box, Button, Divider, Grid, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import {Snackbar} from "@mui/material";
import {useResetPasswordMutation} from "../../api/authApi";
import Progress from "../../components/Progress";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import {useNavigate} from 'react-router-dom';
import LoginLeftSideImage from "../../components/LoginLeftSideImage";
import {toast,ToastContainer} from "react-toastify";
import {theme} from "../../layout/stylesheet";


type NewPasswordValues = {
    username: string;
    password: string;
    confirmPassword: string;
    code: string;
};

const NewPassword = () => {

    const {
        register,
        handleSubmit,
        formState: {errors},
        getValues,
    } = useForm<NewPasswordValues>({mode: "onBlur"});
    const navigate = useNavigate();
    // const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [resetPassword] = useResetPasswordMutation();
    const [showPass, setShowPass] = useState(false);
    const [showconfpass, setshowconfpass] = useState(false);


    const handleClickShowPassword = () => setShowPass((show) => !show);
    const handleClickShowCofPassword = () => setshowconfpass((show) => !show);
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    const handleMouseDownConPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    // const handleSnackbarClose = () => {
    //     setSnackbarOpen(false);
    // };

    useEffect(() => {
        if (loginError) {
            toast.error(loginError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }, [loginError]);

    const onSubmit = async (data: NewPasswordValues) => {
        setIsLoading(true);
        setLoginError("");

        try {
            const userName = localStorage.getItem("username");
            const result: any = await resetPassword({
                ...data,
                username: userName || "",
            });
            console.log(`Reset Password Result : ${JSON.stringify(result)}`);
            if (result.data?.data) {
                localStorage.setItem("username", data.username);
                navigate('/');
                localStorage.clear()

            } else {
                console.log(result);
                setLoginError("Your OTP not valid.");
                // setSnackbarOpen(true);
            }
        } catch (error) {
            console.log(error);
            setLoginError("Your OTP not valid.");
            // setSnackbarOpen(true);
        }
        setIsLoading(false);
    };

    const validateConfirmPassword = (value: string) => {
        const {password} = getValues();
        return value === password || "Passwords do not match";
    };

    if (isLoading) {
        return <Progress/>;
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Box sx={{
                width: '100%',
                position: 'relative',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height: '100vh'
            }}>
                <Box sx={{width: {sm:'90%',md:'90%',lg:'80%',xl:'75%'}, height: 'auto'}}>
                    <Grid container spacing={0}>
                        <Grid item xs={0} md={0} lg={4} xl={3} sx={{display:{xs:'none',sm:'none',lg:'flex'},
                            justifyContent:'right',
                        }}>
                            <LoginLeftSideImage/>
                        </Grid>
                            <Grid item xs={12} md={12} lg={8} xl={9}>
                            <Box sx={{

                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: {xs:'46px',sm:'46px',md:'46px',lg:'46px 0px 0px 46px'},
                                position: 'relative',
                                height:'auto',
                                boxShadow:8,
                                minHeight:'80vh'
                            }}>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    pt: 5,
                                    pl: 3,
                                    pr: 3
                                }}>
                                    <Link to="/forgot-password">
                                        <ArrowBackIcon/>
                                    </Link>
                                    <Typography variant="h5" sx={{ml: 5, color: theme.palette.secondary.main}}>
                                        Reset your password
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignContent: 'center',
                                        mt: 8,
                                        mb: 3
                                    }}
                                >
                                    <Box style={{width:'60%'}}>
                                        <Typography variant="subtitle1" sx={{mb: 4, color: theme.palette.text.primary}}>
                                            Please enter your OTP and new password
                                        </Typography>
                                        <form onSubmit={handleSubmit(onSubmit)}  style={{width:'100%',marginBottom:'80px'}}>
                                            <div style={{alignItems: "center", justifyContent: "center"}}>
                                                <TextField
                                                    sx={{width: "100%", height: "50px"}}
                                                    label="OTP"
                                                    {...register("code", {required: true, pattern: /^[0-9]+$/,})}
                                                    error={!!errors.code}
                                                    helperText={errors.code && "Invalid OTP"}
                                                />
                                            </div>
                                            <div style={{paddingTop: "40.149px"}}>
                                                <TextField
                                                    sx={{width: "100%", height: "50px"}}
                                                    label="New Password"
                                                    type={showPass ? "text" : "password"}
                                                    {...register("password", {
                                                        required: true,
                                                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!_])(.{10,})$/,
                                                    })}
                                                    error={!!errors.password}
                                                    helperText={
                                                        errors.password
                                                            ? "Password must contain at least one lowercase letter, uppercase letter, special character and be at least 10 characters long"
                                                            : ""
                                                    }
                                                    style={errors.password ? {marginBottom: "10px"} : {marginBottom: "0"}}
                                                    InputProps={{ // <-- This is where the toggle button is added.
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPass ? <Visibility/> : <VisibilityOff/>}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    paddingTop: "48.149px",
                                                }}
                                            >
                                                <TextField
                                                    sx={{width: "100%", height: "50px"}}
                                                    label="Confirm Password"
                                                    type={showconfpass ? "text" : "password"}
                                                    {...register("confirmPassword", {
                                                        required: true,
                                                        validate: validateConfirmPassword,
                                                    })}
                                                    error={!!errors.confirmPassword}
                                                    helperText={errors.confirmPassword ? errors.confirmPassword.message ? errors.confirmPassword.message : "Required" : ""}
                                                    InputProps={{ // <-- This is where the toggle button is added.
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowCofPassword}
                                                                    onMouseDown={handleMouseDownConPassword}
                                                                >
                                                                    {showconfpass ? <Visibility/> : <VisibilityOff/>}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <Box sx={{mt: 4,width:'100%',display:'flex',justifyContent:'center'}}>
                                                <Link to="/forgot-password">
                                                    <Button variant="text" type="submit" size="small"
                                                            sx={{textTransform: 'none',color: theme.palette.text.primary, backgroundColor: theme.palette.secondary.main, ":hover": {
                                                                    bgcolor:theme.palette.secondary.light,
                                                                }}}>RESEND OTP</Button>
                                                </Link>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    pt: "20px",
                                                }}
                                            >

                                                <Button type="submit" variant="contained" sx={{
                                                    color: theme.palette.text.primary,
                                                    backgroundColor: theme.palette.secondary.main, ":hover": {
                                                        bgcolor:theme.palette.secondary.light,
                                                    }
                                                }}>
                                                    Reset Password
                                                </Button>
                                                {/*<Snackbar*/}
                                                {/*    open={snackbarOpen}*/}
                                                {/*    anchorOrigin={{vertical: 'top', horizontal: 'right'}}*/}
                                                {/*    autoHideDuration={6000} // Adjust the duration as needed*/}
                                                {/*    onClose={handleSnackbarClose}>*/}
                                                {/*    <Alert onClose={handleSnackbarClose} severity="error"*/}
                                                {/*           sx={{width: '100%'}}>*/}
                                                {/*        {loginError}*/}
                                                {/*    </Alert>*/}
                                                {/*</Snackbar>*/}
                                            </Box>
                                        </form>
                                    </Box>
                                </Box>


                                <Box sx={{
                                    pt: 5,
                                    pl: 3,
                                    pr: 3
                                }}>


                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default NewPassword;
