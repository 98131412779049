import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    logoutSuccess,
    loginRequest,
    loginSuccess,
    loginFailure,
    signupRequest,
    signupFailure,
    signupSuccess,
    setTokenExpired,
    updateToken
} from "./slices/authSlice";
import {API_PATHS} from "../configs/api";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_PATHS.BASE_URL,
        prepareHeaders: (headers, {getState}) => {
            const accessToken = (getState() as any).auth.accessToken;
            if (accessToken && accessToken.jwtToken) {
                headers.set("Authorization", `Bearer ${accessToken.jwtToken}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({

        login: builder.mutation<LoginResponse, LoginCredentials>({
            query: (credentials) => ({
                url: API_PATHS.AUTH.LOGIN,
                method: "POST",
                body: credentials,
            }),
            async onQueryStarted(
                {username, password},
                {dispatch, queryFulfilled}
            ) {
                dispatch(loginRequest());
                try {
                    const {data} = await queryFulfilled;
                    dispatch(
                        loginSuccess({
                            accessToken: data.data.accessToken,
                            refreshToken: data.data.refreshToken,
                            userEmail: username,
                        })
                    );
                } catch (error) {
                    dispatch(loginFailure("Login error"));
                }
            },
        }),

        refresh: builder.mutation<refreshResponse,refreshType>({
            query: (refreshToken) => ({
                url: API_PATHS.AUTH.REFRESH,
                method: "POST",
                body: refreshToken,
            }),
            async onQueryStarted({refreshToken},{dispatch, queryFulfilled}) {
                try {
                    const data = await queryFulfilled;
                    dispatch(updateToken({accessToken: data.data.accessToken}));
                    
                } catch (error) {
                    console.log(` error`,error);
                }
            },
        }),
        logout: builder.mutation({
            query: () => ({
                url: API_PATHS.AUTH.SIGNOUT,
                method: "POST",
            }),
            async onQueryStarted({dispatch, queryFulfilled}) {
                try {
                    const data = await queryFulfilled;
                    
                    dispatch(logoutSuccess());
                } catch (error) {
                    console.log(`Logout error`);
                }
            },
        }),
        signup: builder.mutation<SignUpResponse, SignupCredentials>({
            query: (credentials) => ({
                url: API_PATHS.AUTH.SIGNUP,
                method: "POST",
                body: credentials,
            }),
            async onQueryStarted(
                {fullName, email, password},
                {dispatch, queryFulfilled}
            ) {
                dispatch(signupRequest());
                try {
                    const {data} = await queryFulfilled;
                    dispatch(signupSuccess({username: data.data.user.user.username}));
                } catch (error) {
                    dispatch(signupFailure("Signup error"));
                }
            },
        }),
        confirmSignUp: builder.mutation<
            ConfirmSignUpResponse,
            ConfirmSignUpRequest
        >({
            query: (credentials) => ({
                url: API_PATHS.AUTH.CONFIRM_SIGNUP,
                method: "POST",
                body: credentials,
            }),
            async onQueryStarted({email, code}, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    
                } catch (error) {
                    console.log(`Email verification error`);
                }
            },
        }),
        forgotPassword: builder.mutation<
            ForgotPasswordResponse,
            ForgotPasswordRequest
        >({
            query: (credentials) => ({
                url: API_PATHS.AUTH.FORGOT_PASSWORD,
                method: "POST",
                body: credentials,
            }),
            async onQueryStarted({username}, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                   
                } catch (error) {
                    console.log(`Forgot password error`);
                }
            },
        }),
        resetPassword: builder.mutation<
            ResetPasswordResponse,
            ResetPasswordRequest
        >({
            query: (credentials) => ({
                url: API_PATHS.AUTH.RESET_PASSWORD,
                method: "POST",
                body: credentials,
            }),
            async onQueryStarted({code, username, password, confirmPassword}, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    
                } catch (error) {
                    console.log(`Reset password error`);
                }
            },
        }),
    }),
});

export const {
    useLoginMutation,
    useRefreshMutation,
    useLogoutMutation,
    useSignupMutation,
    useConfirmSignUpMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation
} = authApi;

export interface LoginCredentials {
    username: string;
    password: string;
}

export interface RefreshTypes{
    refreshToken: string
}

export interface SignupCredentials {
    fullName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

export interface ConfirmSignUpRequest {
    email: string;
    code: string;
}

export interface ForgotPasswordRequest {
    username: string;
}

export interface ResetPasswordRequest {
    username: string;
    password: string;
    confirmPassword: string;
    code: string;
}

type ResetPasswordResponse = {
    message: string;
    data: string;
};

type ConfirmSignUpResponse = {
    message: string;
    data: string;
};

type ForgotPasswordResponse = {
    message: string;
    data: string;
};

interface LoginResponse {
    message: string;
    data: {
        accessToken: {
            jwtToken: string;
            payload: {
                sub: string;
                "cognito:groups": string[];
                iss: string;
                client_id: string;
                origin_jti: string;
                event_id: string;
                token_use: string;
                scope: string;
                auth_time: number;
                exp: number;
                iat: number;
                jti: string;
                username: string;
            };
        };
        idToken: {
            jwtToken: string;
            payload: {
                "cognito:groups": string[];
                iss: string;
                sub: string;
                "cognito:username": string;
                given_name: string;
                aud: string;
                event_id: string;
                token_use: string;
                auth_time: number;
                exp: number;
                iat: number;
                email: string;
                name: string;
                jti: string;
            };
        };
        refreshToken: {
            token: string;
        }
    };
}

export interface refreshResponse{
    accessToken:string
    idToken:string
}

export interface refreshType{
    refreshToken:string
}

interface SignUpResponse {
    message: string;
    data: {
        user: {
            user: {
                username: string;
                pool: {
                    userPoolId: string;
                    clientId: string;
                    client: {
                        endpoint: string;
                        fetchOptions: Record<string, unknown>;
                    };
                    advancedSecurityDataCollectionFlag: boolean;
                };
                Session: null;
                client: {
                    endpoint: string;
                    fetchOptions: Record<string, unknown>;
                };
                signInUserSession: null;
                authenticationFlowType: string;
                keyPrefix: string;
                userDataKey: string;
            };
            userConfirmed: boolean;
            userSub: string;
            codeDeliveryDetails: {
                AttributeName: string;
                DeliveryMedium: string;
                Destination: string;
            };
        }
    };
}

interface EmailRequest {
    sender: string;
    message: string;
    subject: string;
}

interface EmailResponse {
    message: string;
}

export interface ProfileRequest {
    name: string;
    email: string;
    country: string;
    zipCode: string;
}
