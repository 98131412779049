import * as React from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

export interface DatePickerValueProps {
    onDateChange: (selectedDate: Dayjs | null) => void;
    defaultValue:Dayjs | null;
}
export default function DatePickerValue(props: DatePickerValueProps) {
    const [value, setValue] = React.useState<Dayjs | null>(dayjs);
    const today = new Date();
    const dateObj = new Date(today);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;


        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(()=>{
            setValue(props.defaultValue);
        }, []);




    const onKeyDown = (e:React.KeyboardEvent<HTMLImageElement>) => {
        e.preventDefault();
    };

    //console.log(formattedDate);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                    label="Date *"
                    minDate={dayjs(formattedDate)}
                    disablePast
                    value={value}
                    slotProps={{
                        textField: {onKeyDown:onKeyDown},
                    }}
                    onChange={(value) => {
                        setValue(value);
                        props.onDateChange(value);
                    }}
                    sx={{flex: 1, flexBasis: "50%"}}
                    format="DD-MM-YYYY"
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}