export const API_PATHS = {
  BASE_URL: 'https://wdv0t6747c.execute-api.us-east-1.amazonaws.com/PRD/',
  AUTH: {
    LOGIN: '/auth/login',
    REFRESH: '/auth/refresh',
    SIGNUP: '/auth/signup',
    SIGNOUT: '/auth/signout',
    CONFIRM_SIGNUP: '/auth/confirm-signup',
    ENABLE_2MFA: '/auth/enable-2mfa',
    FORGOT_PASSWORD: "/auth/forgotPasswordCode",
    RESET_PASSWORD: "/auth/forgotPassword",
  },
  USER: {
    GET_USER_BY_ID: '/user/getById',
    GET_USER_BY_EMAIL: '/user/getByEmail',
    UPDATE_USER_BY_ID: '/user/update',
  },

  EVENT: {
    LIST: '/event/list',
    UPDATE: '/event/update',
    DELETE:'/event/delete',
    CREATE:'/event/create'
  },
  MEDIA_LIVE:{
    CREATE_INPUT: '/media-live/createInput',
    START_CHANNEL: '/media-live/startChannel',
    STOP_CHANNEL: '/media-live/stopChannel',
    DELETE_INPUT: '/media-live/deleteInput',
    DELETE_CHANNEL:'/media-live/deleteChannel',
    CREATE_CHANNEL: '/media-live/createChannel'
  },
  MEDIA_PACKAGE:{
    CREATE_CHANNEL: '/media-package/createChannel',
    CREATE_END_POINT: '/media-package/createEndpoint',
    DELETE_END_POINT: '/media-package/deleteEndpoint',
    DELETE_CHANNEL: '/media-package/deleteChannel',
  },
  CLOUDFRONT_DISTRIBUTION:{
    DISABLE_DISTRIBUTION: '/cloudfront/disable',
  },
  SSH:{

    CREATE_PARAM:'/ssm/createParameter',

  }
} as const
