import React, {useEffect, useState} from 'react'
import {Box, Typography} from "@mui/material";
import logo from "../assets/logo/logo.png";
import wallpaper from "../assets/images/blue wallpaper.jpg";
import ReactTextTransition from "react-text-transition";
import getRandomInt from "./getRandomNumber";
import {theme} from "../layout/stylesheet";
const LoginLeftSideImage = () => {
    const heading = ["Elegant", "The No.1", "Streameg", "Creative"]
    const [randomIndex, setRandomIndex] = useState(0);
    useEffect(() => {
        let interval = setInterval(() => {
            setRandomIndex(getRandomInt(0, heading.length));

        }, 3000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            <Box sx={{position: 'relative',
                width:'100%',
                display:'flex',
                // justifyContent:'center',
                alignItems:'right',
                flexDirection:'column'
            }}>

                <img
                    src={logo}
                    alt="Logo"
                    width='40%'
                    height='auto'
                    style={{position: 'absolute', top: '5%', left: '15%',}}
                />

                <img
                    src={wallpaper}
                    alt="wallpaper"
                    style={{
                        position: 'absolute',
                        height: '100%',
                        zIndex: -1,
                        width: '100%'
                        , objectFit: 'cover'
                        , marginLeft: '30px'
                        , boxShadow: ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
                    }}
                />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexItem: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        // pt: "64%",
                        height:'100%'
                    }}
                >
                    <Typography variant="h3">
                        We are
                    </Typography >
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography
                            variant="h3">
                            <ReactTextTransition inline>
                                {<Typography variant="h3" sx={{fontWeight: 'bold'}}>
                                    {`${heading[randomIndex]}`}
                                </Typography>}
                            </ReactTextTransition>

                        </Typography>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default LoginLeftSideImage
