import {Theme} from '@mui/material'
import { alpha } from "@mui/material/styles";
import {red} from "@mui/material/colors";

const Button = (theme: Theme) => {
    return {
        MuiButton: {
            
            variants: [
                {
                    props: { variant: "text", color: "primary" },
                    style: {
                        textTransform: "none",
                        border: "none",
                        backgroundColor: red,
                        color: "#fff",
                        borderRadius: 8,
                        

                    },

                }
            ]
        },
    }
}

export default Button
