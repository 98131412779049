import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {Button, IconButton, TextField} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import GoLiveButton from "../GoLiveButton";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from "@mui/material/AccordionDetails";
import AdvanceSettings from "../AdvanceSettings";
import {Controller, useForm} from "react-hook-form";
import {useUpdateEventByIdMutation} from "../../api/eventApi";
import BasicDatePicker from "../BasicDataPicker";
import {useNavigate} from "react-router-dom";
import CopyText from "../CopyText";
import dayjs, {Dayjs} from "dayjs";
import BasicTimePicker from "../BasicTimePicker";
import {timeToUTC} from "../../utils/timeToUTC";
import scheduleLive from "../../pages/schedule-live/ScheduleLive";
import {getTimeDate} from "../../utils/getTimeDate";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useCreateChannelMutation, useSshCreateParamMutation} from "../../api/mediaLiveApi";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiChannelScheduling from '../MultiChannelScheduling';
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import unsecuredCopyToClipboard from "../../utils/unsecuredCopyToClipboard";
import {useState} from "react";

type ScheduleDetailValues = {
    title: string
    description: string
    facebookStreamKey: string
    youtubeStreamKey: string
    twitchStreamKey: string
    startTime: string
    startDate: string
    tags: string[]

}

interface ScheduleDetailsProps {
    onDelete: (eventId: string) => void
    eventData: any
    refetch: () => void

}

const ScheduleDetails = ({eventData, onDelete, refetch}: ScheduleDetailsProps) => {

    const [facebookStreamKey, setFacebookStreamKey] = React.useState(eventData.event?.facebookStreamKey || '');
    const [youtubeStreamKey, setYoutubeStreamKey] = React.useState(eventData.event?.youtubeStreamKey || '');
    const [twitchStreamKey, setTwitchStreamKey] = React.useState(eventData.event?.twitchStreamKey || '');

    const [channel1Active, setChannel1Active] = React.useState(false);
    const [channel2Active, setChannel2Active] = React.useState(false);
    const [channel3Active, setChannel3Active] = React.useState(false);


    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
        setValue,
        getValues
    } = useForm<ScheduleDetailValues>({mode: "onBlur"});

    const handleFacebookStreamKeyChange = (value: string) => {
        console.log('facebook', value);
        setFacebookStreamKey(value);
        setValue('facebookStreamKey', value);
    };


    const handleYoutubeStreamKeyChange = (value: string) => {
        console.log('youtube', value);
        setYoutubeStreamKey(value);
        setValue('youtubeStreamKey', value);
    };

    const handleTwitchStreamKeyChange = (value: string) => {
        console.log('twitch', value);
        setTwitchStreamKey(value);
        setValue('twitchStreamKey', value);
    };

    const [updateEventById] = useUpdateEventByIdMutation();
    // console.log(getTimeDate().date, getTimeDate().time);
    const [expanded, setExpanded] = React.useState<string | false>(false);


    const [sshCreateParam] = useSshCreateParamMutation();
    const [createChannel] = useCreateChannelMutation();

    const [disablePast, setDisablePast] = React.useState(true);
    const [isTimePickerEnable, setTimePickerEnable] = React.useState(true);

    const [timePickerVal, setTimePickerVal] = React.useState(dayjs(eventData.event?.startTime));
    const [datePickerVal, setDatePickerVal] = React.useState(dayjs(eventData.event?.startDate));


    React.useEffect(() => {
        setValue('startTime', timePickerVal.toString());
        setValue('startDate', eventData.event?.startDate);
        setFacebookStreamKey(eventData.event?.facebookStreamKey || '');
        setValue('facebookStreamKey', eventData.event?.facebookStreamKey);
        setYoutubeStreamKey(eventData.event?.youtubeStreamKey || '');
        setValue('youtubeStreamKey', eventData.event?.youtubeStreamKey);
        setTwitchStreamKey(eventData.event?.twitchStreamKey || '');
        setValue('twitchStreamKey', eventData.event?.twitchStreamKey);
        console.log('loop')
    }, [eventData]);


    const handleDatePicker = (StartDate: Dayjs | null) => {
        console.log('selected date', StartDate);
        setTimePickerEnable(false);
        if (StartDate) {

            if (getTimeDate().date != StartDate.format('YYYY-MM-DD')) {
                setDisablePast(false);
                console.log('enable past');
            } else {
                setDisablePast(true);
                console.log('disable past');
            }
            setValue('startDate', StartDate.format('YYYY-MM-DD').toString());
            setValue('startTime', timeToUTC({'date': StartDate, 'time': timePickerVal}));
        }
    }


    const handleTimePickerChange = (selectedTime: Dayjs | null) => {
        if (selectedTime) {
            console.log("selected date:" + dayjs(selectedTime).format('HH:mm'), "event date" + eventData.event?.startDate);
            console.log("formatted date -------", timeToUTC({
                'date': dayjs(getValues("startDate")),
                'time': selectedTime
            }));
            setValue('startTime', timeToUTC({'date': dayjs(getValues("startDate")), 'time': selectedTime}));

        }
    }

    const [opennT, setOpenT] = useState(false);

    const handleClickCopy = (value: any) => {
        setOpenT(true);
        try {
            setOpenT(true);
            toast.info('Copied to clipboard', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(value.toString());
            } else {
                unsecuredCopyToClipboard(value.toString());
            }
        } catch (err) {
            console.error('Copy failed:', err)
        }

        setTimeout(() => {
        }, 2000);

    };


    React.useEffect(() => {
        if (datePickerVal.isSame(dayjs(), 'day')) {
            setDisablePast(true);
        } else {
            setDisablePast(false);
        }
    }, [datePickerVal]);
    type Quality = 'FHD' | '' | 'UHD';
    const handleCreateChannel = async (quality: Quality) => {
        console.log("Creating channel");

        const name = eventData?.event?.title.split(" ").join("");
        const id = toast.loading("Please wait...", {
            position: 'top-right',
            theme: "colored", type: "info"
        })
        const value = eventData?.liveStreams?.output?.ingestEndpoints[0]?.password;
        const eventId = eventData?.event?.id;
        const mediaLiveInputId = eventData?.liveStreams?.input?.id;
        const mediaPackageChannelId = eventData?.liveStreams?.output?.id;
        const mediaPackageChannelUrlParam = encodeURIComponent(eventData?.liveStreams?.output?.ingestEndpoints[0]?.url);
        const mediaPackageChannelUsernameParam = eventData?.liveStreams?.output?.ingestEndpoints[0]?.username;
        const mediaPackageChannelPasswordParam = name;
        const facebookStreamKey = eventData?.event?.facebookStreamKey;
        const youtubeStreamKey = eventData?.event?.youtubeStreamKey;
        const twitchStreamKey = eventData?.event?.twitchStreamKey;
        const userID = eventData?.event?.userID;
        const fullHd = quality === 'FHD' ? 'FHD' : '';
        const ultraHd = quality === 'UHD' ? 'UHD' : '';

        console.log("eventId", eventId);
        console.log("mediaLiveInputId", mediaLiveInputId);
        console.log("mediaPackageChannelId", mediaPackageChannelId);
        console.log("mediaPackageChannelUrlParam", mediaPackageChannelUrlParam);
        console.log("mediaPackageChannelUsernameParam", mediaPackageChannelUsernameParam);
        console.log("userID", userID);


        const respo_cparam = await sshCreateParam({name, value});
        if ('data' in respo_cparam) {
            const parsejson = JSON.parse(JSON.stringify(respo_cparam.data));
            //console.log(parsejson['$metadata']['httpStatusCode']);
            if (parsejson['$metadata']['httpStatusCode'] === 200) {
                const respo_crChannel = await createChannel({
                    name,
                    eventId,
                    mediaLiveInputId,
                    mediaPackageChannelId,
                    mediaPackageChannelUrlParam,
                    mediaPackageChannelUsernameParam,
                    mediaPackageChannelPasswordParam,
                    facebookStreamKey,
                    youtubeStreamKey,
                    twitchStreamKey,
                    userID,
                    fullHd,
                    ultraHd
                });

                if ('data' in respo_crChannel) {
                    console.log("Channel create successfully");

                    toast.update(id, {
                        render: "Channel create successfully!",
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                        closeOnClick: false,
                        position: 'top-right',
                        theme: "colored"
                    });
                    return true;
                } else {
                    toast.update(id, {
                        render: `Channel Create unsuccessfully`,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        closeOnClick: false,
                        position: 'top-right',
                        theme: "colored"
                    });
                    return false;
                }
            } else {
                console.log("channel create unsuccessfully")
                toast.update(id, {
                    render: `Channel Create unsuccessfully`,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                    closeOnClick: false,
                    position: 'top-right',
                    theme: "colored"
                });
            }
        }
    }

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
            setValue('title', eventData?.event?.title);
            setTimePickerVal(dayjs(eventData.event?.startTime));
            setDatePickerVal(dayjs(eventData.event?.startDate));
            console.log("expand: ", eventData);
        };
    const [isLoading, setIsLoading] = React.useState(false);
    const [updatingError, setUpdatingError] = React.useState('');

    const onSubmit = async (data: ScheduleDetailValues) => {
        console.log("form data", data);
        if ((new Date(getTimeDate().date).getTime() > new Date(data.startDate).getTime()) || ((getTimeDate().date === data.startDate) && (dayjs(data.startTime).format('HH:mm') < getTimeDate().time))) {

            toast.warning('Allow only times in the future', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        setUpdatingError('');
        console.log("submitted data__________", data);
        try {
            setIsLoading(true);
            const updateId = eventData.event.id;
            await updateEventById({
                id: updateId,
                body: data,
            });
            toast.success('Update successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.log(`Event edit test : ${JSON.stringify(data)} `);
            setInterval(function () {
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.log(error);
            setUpdatingError('Entered values are not valid');
        }

        setIsLoading(false);
    };
    let outputUrl = '';
    if (eventData.liveStreams.output?.cloudFrontEndpoint !== undefined) {
        outputUrl = eventData.liveStreams.output.cloudFrontEndpoint;
    } else {
        outputUrl = ''
    }
    const iframe = `<iframe allow="autoplay"  src="https://iframe.streameg.io/?source=${outputUrl}&title=${eventData.event.title}&desc=${eventData.event.description} " style="overflow: hidden; height: 100vh; width: 100%"></iframe>`;

    const [open, setOpen] = React.useState(false);
    const [createChannelOpen, setCreateChannelOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const createChannelHandleClickOpen = () => {
        setCreateChannelOpen(true);
    };

    const createChannelHandleClickClose = () => {
        setCreateChannelOpen(false);
    };
    const navigate = useNavigate();
    const channelStatus = eventData?.liveStreams?.channelStatus?.start;
    const channelId = eventData?.liveStreams?.channel?.id
    const originEndpoint = eventData?.liveStreams?.output?.originEndpoint
    const liveEventId = eventData?.liveStreams?.eventId
    const title = eventData?.liveStreams?.channel?.name
    const livePreviewOnclick = async () => {
        navigate(`/go-live?id=${channelId}&url=${originEndpoint}&eventId=${liveEventId}&title=${title}`)
    }
    const createHdChannelOnclick = async () => {
        setCreateChannelOpen(false)
        const result = await handleCreateChannel('');

        if (result) {
            refetch()
        }
    }
    const createFullHdChannelOnclick = async () => {
        setCreateChannelOpen(false)
        const result = await handleCreateChannel('FHD');

        if (result) {
            refetch()
        }
    }
    const createUltraHdChannelOnclick = async () => {
        setCreateChannelOpen(false)
        const result = await handleCreateChannel('UHD');

        if (result) {
            refetch()
        }
    }

    const isDisabled = !!eventData?.liveStreams.channel?.id;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const buttonStyle = {
        background: theme.palette.secondary.main,
        border: '4px solid transparent',
        '&:hover': {
            border: `4px solid ${theme.palette.secondary.main}`,
        },
        margin: '2px 10px',
    };

    const gutterBottom = {
         marginBottom: '10px',
    };

    return (

        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"

            />
            <ToastContainer/>
            <Accordion
                sx={{mb: "1rem"}}
                expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"

                >
                    <Box
                        sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}
                    >
                        <Typography variant={expanded === 'panel1' ? 'h4' : 'body1'} sx={{
                            width: '33%',
                            flexShrink: 0,
                            ml: expanded === 'panel1' ? 5 : 2,
                            mt: expanded === 'panel1' ? 2 : 1,
                        }}>
                            {eventData?.event?.title}
                        </Typography>

                        <Box sx={{mr: 5, display: 'flex'}}>

                            <div>
                                <Button disabled={(eventData?.liveStreams.channel?.id)} variant='contained' size='small'
                                        sx={{
                                            marginRight: '10px',
                                            py: 0.8,
                                            background: theme.palette.secondary.main,
                                            color: theme.palette.text.primary,
                                            textTransform: 'capitalize',
                                            width: '120px',
                                        }}
                                        onClick={createChannelHandleClickOpen}
                                >Create Channel</Button>
                            </div>

                            <Dialog
                                fullScreen={fullScreen}
                                open={createChannelOpen}
                                onClose={createChannelHandleClickClose}
                                aria-labelledby="responsive-dialog-title2"
                            >
                                <DialogContent>
                                    <DialogContentText>
                                        <DialogTitle sx={{ m: 0, p: 2 ,color: theme.palette.primary.main}} id="customized-dialog-title">
                                            Output Rendition
                                        </DialogTitle>
                                        <IconButton
                                            aria-label="close"
                                            onClick={createChannelHandleClickClose}
                                            sx={{
                                                position: 'absolute',
                                                right: 25,
                                                top: 25,
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <DialogContent dividers>
                                            <Typography sx={gutterBottom}>
                                                <b>High Definition (HD)</b> is more than 480, but less than or equal to 720 resolution.
                                            </Typography>
                                            <Typography sx={gutterBottom}>
                                                <b>Full High Definition (Full HD)</b> is more than 720, but less than or equal to 1080 resolution.
                                            </Typography>
                                            <Typography sx={gutterBottom}>
                                                <b>Ultra High Definition (Ultra HD)</b> is more than 1080, but less than or equal to 4K resolution.
                                            </Typography>
                                        </DialogContent>
                                        <DialogActions>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    '& > *': {
                                                        m: 3,
                                                    },
                                                }}
                                            >
                                                <>
                                                    <Button sx={buttonStyle} onClick={createHdChannelOnclick}>HD</Button>
                                                    <Button sx={buttonStyle} onClick={createFullHdChannelOnclick}>FULL HD</Button>
                                                    <Button sx={buttonStyle} onClick={createUltraHdChannelOnclick}>ULTRA HD</Button>
                                                </>
                                            </Box>

                                        </DialogActions>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>
                            <GoLiveButton key={eventData.id}
                                          channelSettings={{
                                              channelDetails: eventData?.liveStreams.channel?.id,
                                              liveId: eventData?.id,
                                              outputUrl: eventData?.liveStreams.output?.cloudFrontEndpoint,
                                              eventId: eventData?.liveStreams?.eventId,
                                              title: eventData?.liveStreams?.channel?.name,
                                              channelStatus: eventData?.liveStreams?.channelStatus?.start
                                          }}/>

                            <div><Button disabled={!channelStatus} onClick={livePreviewOnclick}
                                         variant='contained' size='small' sx={{
                                py: 0.8,
                                marginLeft: '10px',
                                background: theme.palette.secondary.main,
                                color: theme.palette.text.primary,
                                textTransform: 'capitalize',
                                width: '120px',
                            }}>
                                Live preview</Button>
                            </div>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            < Grid item xs={12}>
                                <Box
                                    component="form"
                                    noValidate
                                    sx={{
                                        display: 'grid',
                                        gap: 3,
                                        width: '80%',
                                        mt: 5,
                                        ml: 10,
                                    }}
                                >
                                    <Controller control={control}  {...register('title', {required: true})} render={
                                        ({
                                             field: {onChange, value},

                                         }) => (<TextField
                                            sx={{width: '100%'}}
                                            onChange={onChange}
                                            value={value}
                                            defaultValue=" "
                                            label="Title *"
                                            error={!!errors.title}
                                            helperText={errors.title && "Title is required"}
                                        ></TextField>)
                                    }/>
                                    <TextField
                                        label="Description *"
                                        multiline
                                        id="outlined-multiline-flexible"
                                        maxRows={4}
                                        defaultValue={eventData?.event?.description}
                                        margin="normal"
                                        {...register('description')}
                                        fullWidth
                                        rows={4}
                                    />
                                    <TextField
                                        label="Tags"
                                        disabled={false}
                                        defaultValue={eventData?.event?.tags?.join('  |  ')}
                                        margin="normal"
                                        {...register('tags')}
                                        fullWidth
                                        placeholder={"No Tags Provided"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <Grid container spacing={2}>
                                        <Grid sx={{width: '40%'}} item>
                                            <BasicDatePicker
                                                onDateChange={handleDatePicker} defaultValue={datePickerVal}/>
                                        </Grid>
                                        <Grid sx={{width: '40%'}} item style={{marginLeft: '20%'}}>
                                            <BasicTimePicker
                                                onTimeChange={handleTimePickerChange}
                                                isDisablePast={disablePast}
                                                isDisable={false}
                                                defaultValue={timePickerVal}
                                            />
                                        </Grid>
                                    </Grid>

                                    <CopyText val={iframe}/>

                                    <Box sx={{width: '75%',}}>
                                        <AdvanceSettings
                                            key={eventData.event?.id}
                                            eventSettings={{
                                                streamUrl: eventData?.liveStreams?.input?.streamUrl,
                                                streamKey: eventData?.liveStreams?.input?.streamKey,
                                                cloudFrontUrl: eventData?.liveStreams?.output?.cloudFrontEndpoint
                                            }}
                                        />


                                    </Box>
                                    <Box>
                                        <Typography variant='body1' sx={{
                                            my: '1%',pl:'1%'
                                        }}>Select Live Stream Destinations
                                        </Typography>
                                        <Box sx={{m: '3%'}}>
                                            <MultiChannelScheduling
                                                facebookStreamKey={facebookStreamKey}
                                                youtubeStreamKey={youtubeStreamKey}
                                                twitchStreamKey={twitchStreamKey}
                                                onFacebookStreamKeyChange={handleFacebookStreamKeyChange}
                                                onYoutubeStreamKeyChange={handleYoutubeStreamKeyChange}
                                                onTwitchStreamKeyChange={handleTwitchStreamKeyChange}
                                                onChannel1ToggleChange={setChannel1Active}
                                                onChannel2ToggleChange={setChannel2Active}
                                                onChannel3ToggleChange={setChannel3Active}
                                                isDisabled={isDisabled}

                                            />
                                        </Box>
                                    </Box>

                                </Box>
                                <Box sx={{width: '60%', ml: 10}}>
                                    <TextField
                                        label="Output Stream URL"
                                        defaultValue={eventData?.liveStreams?.output?.cloudFrontEndpoint}
                                        margin="normal"
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">

                                                    <IconButton onClick={() => handleClickCopy(eventData?.liveStreams?.output?.cloudFrontEndpoint)} color="primary">
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',

                                        alignItems: 'center',
                                        justifyContent: "flex-end",
                                        p: 1,
                                        mr: 6,
                                    }}
                                >
                                    <Box>

                                        <Button disabled={!!channelStatus} variant="outlined" color="secondary"
                                                onClick={handleClickOpen}
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    color: theme.palette.secondary.main,
                                                    borderColor: theme.palette.secondary.main,
                                                    width: '120px',
                                                }}>
                                            Delete
                                        </Button>
                                        <Button variant="contained" type="submit"
                                                sx={{
                                                    ml: '30px',
                                                    textTransform: 'capitalize',
                                                    background: theme.palette.secondary.main,
                                                    color: theme.palette.text.primary,

                                                }}>
                                            Save Changes
                                        </Button>


                                        <Dialog
                                            fullScreen={fullScreen}
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="responsive-dialog-title"

                                        >
                                            <DialogTitle id="responsive-dialog-title">

                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Are you sure you want to delete this Item ?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions sx={{
                                                m: '10px',
                                                px: '10px'
                                            }}>
                                                <Button onClick={() => {
                                                    onDelete(eventData?.event?.id);
                                                    setOpen(false)
                                                }} autoFocus variant='contained' color='error' size='small' sx={{
                                                    color: 'white',
                                                    px: '20px'
                                                }}>
                                                    Delete
                                                </Button>

                                                <Button autoFocus onClick={handleClose} color='info' variant='outlined'
                                                        size='small' sx={{
                                                    color: 'white',
                                                    mr: '20px',
                                                    px: '20px'

                                                }}>
                                                    Cancel
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
export default ScheduleDetails
