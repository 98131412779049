import {Box, Button, Container} from "@mui/material";
import * as React from "react";
import {theme} from "../layout/stylesheet";
import store from "../app/store";


const reduxState = store.getState()

interface ButtonComponentProps {
    onReset: () => void;
    onClick: () => void;
    eventNID: string | undefined;
}

const SaveResetButtonGroup: React.FC<ButtonComponentProps & { eventNID: string | undefined }> = ({ onClick, onReset, eventNID }) => {
    return (
        <Container disableGutters>
            <Box sx={{display: "flex", justifyContent: "flex-end", gap: 2, mt: 4, mb: 2}}>
                <Button  onClick={onReset} variant="outlined" sx={{outlineStyle: theme.palette.secondary.main,color:theme.palette.text.primary }}> Reset </Button>
                <Button  disabled={!!eventNID} onClick={onClick} variant="contained" sx={{background: theme.palette.secondary.main, color:theme.palette.text.primary , ":hover": {
                        bgcolor:theme.palette.secondary.light,
                    }}}>Save </Button>
            </Box>
        </Container>
    )
}
// disabled={!!channelStatus}
export default SaveResetButtonGroup