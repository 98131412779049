import React from 'react'
import {Box, Card, CardContent, CardMedia, Typography} from '@mui/material'

type VideoCardProps = {
    id: number
    title: string
    subTitle: string
    thumbnailUrl: string
    videoUrl: string
    createDate: string
}

export default function VideoCard({
                                      id,
                                      title,
                                      subTitle,
                                      thumbnailUrl,
                                      videoUrl,
                                      createDate,
                                  }: VideoCardProps) {
    return (
        <>
            <Card
                sx={{
                    maxWidth: 300,
                    maxHeight: 350,
                    borderRadius: '20px',
                    position: 'relative',
                }}
                elevation={3}
            >
                <CardMedia
                    sx={{height: '350px', width: '250px'}}
                    image={thumbnailUrl}
                />
                <CardContent sx={{position: 'absolute', bottom: 2}}>
                    <Box sx={{maxWidth: 200}}>
                        <Typography
                            noWrap
                            gutterBottom
                            variant="h5"
                            component="div"
                            color="primary.contrastText"
                        >
                            {title}
                        </Typography>
                        <Typography noWrap variant="body2" color="primary.contrastText">
                            {subTitle}
                        </Typography>
                    </Box>
                    <Box sx={{textAlign: 'end'}}>
                        <Typography noWrap variant="body2" color="primary.contrastText">
                            {createDate}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}
