import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Box, Button, Grid} from '@mui/material'

import VideoPreviewCard from '../components/card/VideoPreviewCard'
import videoItems from '../data/videoItems.json'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const DisplayUploadedVideos: React.FC<{
    title?: string
    subTitle?: string
}> = () => {
    const handleCardClick = (
        videoUrl: string,
        title: string,
        subTitle: string
    ) => {
        console.log('Clicked Card:', title)
        console.log('Video URL:', videoUrl)
        console.log('Subtitle:', subTitle)
    }
    const [visible, setVisible] = useState(4)
    const canLoadMore = videoItems.length > visible

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4)
    }

    return (
        <>
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <Grid
                    container
                    spacing={2}
                    xs={12}
                    sx={{justifyContent: 'flex-start', rowGap: 5, columnGap: 5}}
                >
                    {videoItems.slice(0, visible).map((item) => (
                        <Link key={item.id} to={`video/${item.id}`}>
                            <Grid item key={item.id}>
                                <VideoPreviewCard
                                    {...item}
                                    onCardClick={(videoUrl, title, subTitle) =>
                                        handleCardClick(videoUrl, title, subTitle)
                                    }
                                />
                            </Grid>
                        </Link>
                    ))}

                </Grid>
                <Box sx={{justifyContent: 'flex-start', mt: 5}}>
                    {canLoadMore && <Button onClick={showMoreItems}>Load more <KeyboardArrowRightIcon/></Button>}
                </Box>

            </Box>
        </>
    )
}

export default DisplayUploadedVideos
