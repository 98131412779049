import React from 'react'
import {useParams} from 'react-router-dom'
import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Grid,
    Typography
} from '@mui/material'

import TitleBar from '../../components/TitleBar'
import videoItems from '../../data/videoItems.json'
import UploadVideoMadal from "../../features/UploadVideoMadal";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatIcon from '@mui/icons-material/Chat';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';

interface Video {
    id: number
    title: string
    subTitle: string
    videoUrl: string
    description?: string
}

const VideoPage: React.FC = () => {
    const {id} = useParams<{ id?: string }>()

    const [isModalOpen, setIsModalOpen] = React.useState(false)
    // Check if id is undefined or null, and set a default value or handle the case accordingly
    const parsedId: number | undefined = id ? parseInt(id, 10) : undefined

    // Find the video by ID
    const video: Video | undefined = parsedId
        ? videoItems.find((video) => video.id === parsedId)
        : undefined

    // Handle video not found scenario
    if (!video) {
        return <div>Video not found!</div>
    }

    const {title, subTitle, videoUrl, description} = video


    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }
    return (
        <Grid container spacing={2} sx={{mb: 2}}>
            <TitleBar title="Video on Demand" buttons={
                <Button
                    onClick={handleOpenModal}
                    variant="contained"
                    color="primary"
                >
                    Upload Video
                </Button>
            }/>
            <UploadVideoMadal isOpen={isModalOpen} onClose={handleCloseModal}/>
            <Grid item xs={12}>
                <Box sx={{
                    pt: 2,
                    pl: 6,
                    pr: 6,
                    pb: 2,
                }}>
                    <Grid item xs={8}>
                        <Container fixed sx={{margin: "auto", padding: 0}}>
                            <Typography variant={'h4'} sx={{mt: 1, mb: 2}}>
                                {title}
                            </Typography>

                            <Box sx={{width: "100%", display: "block"}}>
                                <iframe
                                    title={title}
                                    src={videoUrl}
                                    allowFullScreen={true}
                                    style={{
                                        margin: "0",
                                        width: "100%",
                                        height: "500px",
                                        border: "none",
                                        display: "block",
                                        minHeight: "0",
                                    }}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                ></iframe>
                                <Box sx={{display: "flex", justifyContent: "space-between", mt:2}}>
                                    <Typography variant="h5">
                                        {subTitle}
                                    </Typography>
                                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                                        <Button><RemoveRedEyeIcon/></Button>
                                        <Button><FavoriteIcon/></Button>
                                        <Button><ChatIcon/></Button>
                                        <Button><DownloadIcon/></Button>
                                        <Button><ShareIcon/></Button>
                                    </ButtonGroup>
                                </Box>
                                <Box sx={{mt:2}}>
                                    <Typography variant="h6">Description :</Typography>
                                    <Typography variant="body1">
                                        {description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Container>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default VideoPage
