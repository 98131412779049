import React, {useState} from 'react';
import { Box, Typography, Divider} from '@mui/material';
import Paper from "@mui/material/Paper";
import {theme} from "../layout/stylesheet";
import MultiChannelScheduling from "./MultiChannelScheduling";


interface MultiStreamOutputsProps {
    isFacebookSwitchOn: boolean;
    setIsFacebookSwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
    onFacebookInputChange: (value: string) => void;
    isYoutubeSwitchOn: boolean;
    setIsYoutubeSwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
    onYoutubeInputChange: (value: string) => void;
    isTwitchSwitchOn: boolean;
    setIsTwitchSwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
    onTwitchInputChange: (value: string) => void;

}


function MultiStreamOutputs(props: MultiStreamOutputsProps) {
    const [facebookStreamKeyInput, setFacebookStreamKeyInput] = useState('');
    const [youtubeStreamKeyInput, setYoutubeStreamKeyInput] = useState('');
    const [twitchStreamKeyInput, setTwitchStreamKeyInput] = useState('');
    function handleFacebookStreamKeyChange(value: string) {
        console.log(value);
        setFacebookStreamKeyInput(value);
        props.onFacebookInputChange(value);
    }

    function handleTwitchStreamKeyChange(value: string) {
        console.log(value);
        setTwitchStreamKeyInput(value);
        props.onTwitchInputChange(value);
    }

    function handleYoutubeStreamKeyChange(value: string) {
        console.log(value);
        setYoutubeStreamKeyInput(value);
        props.onYoutubeInputChange(value);
    }

    function handleChannel1ToggleChange(val: boolean) {
        console.log("Facebook Toggle", val);
    }

    function handleChannel2ToggleChange(val: boolean) {
        console.log("Channel 2 Toggle", val);
    }

    function handleChannel3ToggleChange(val: boolean) {
        console.log("Channel 3 Toggle", val);
    }

    return (
        <div>
            <Paper sx={{ p: 2, pb: 3,}}>
                <Box sx={{ mb: 2 }}>
                    <Typography color={theme.palette.text.primary} variant='h6'>
                        Select Live Stream Destinations
                    </Typography>
                    <Divider />
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    pl: 3,
                    pt:2
                }}>
                    <MultiChannelScheduling
                        facebookStreamKey={facebookStreamKeyInput}
                        youtubeStreamKey={youtubeStreamKeyInput}
                        twitchStreamKey={twitchStreamKeyInput}
                        onFacebookStreamKeyChange={handleFacebookStreamKeyChange}
                        onTwitchStreamKeyChange={handleTwitchStreamKeyChange}
                        onYoutubeStreamKeyChange={handleYoutubeStreamKeyChange}
                        onChannel1ToggleChange={handleChannel1ToggleChange}
                        onChannel2ToggleChange={handleChannel2ToggleChange}
                        onChannel3ToggleChange={handleChannel3ToggleChange}
                    />
                </Box>
            </Paper>

        </div>
    )
        ;
}

export default MultiStreamOutputs;

