export function sortEvents(arr:string,orderby:string):string{
    if(arr !== undefined){


        if (orderby === "name") {
            var sort_name = eval(arr).sort((a: any, b: any) => {
                if (a.event.title < b.event.title) {
                    return -1;
                } else if (a.event.title > b.event.title) {
                    return 1;
                } else {
                    return 0;
                }

            });
            console.log("sorted.by name", sort_name);
            return sort_name;
        } else if (orderby === "time") {

            return eval(arr).sort((a: any, b: any) => {
                if (a.event.startTime > b.event.startTime) {
                    return -1;
                } else if (a.event.startTime < b.event.startTime) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
    }

        return arr;




}