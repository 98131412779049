import React, {useState} from "react";
import {Box, TextField} from "@mui/material";
import { IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InputAdornment from '@mui/material/InputAdornment';
import unsecuredCopyToClipboard from "../utils/unsecuredCopyToClipboard";
import {toast} from "react-toastify";
import {Visibility, VisibilityOff} from "@mui/icons-material";

interface AdvancedSettingsProps {
    eventSettings: {
        streamUrl?: string;
        streamKey?: string;
        cloudFrontUrl?: string;
    }
}

const AdvanceSettings = (props: AdvancedSettingsProps) => {
    const {eventSettings} = props;

    const [open, setOpen] = useState(false);

    const handleClick = (value: any) => {
        setOpen(true);
        try {
            setOpen(true);
            toast.info('Copied to clipboard', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(value.toString());
            } else {
                unsecuredCopyToClipboard(value.toString());
            }
        } catch (err) {
            console.error('Copy failed:', err)
        }

        setTimeout(() => {
        }, 2000);

    };

    const [showStreamKey, setshowStreamKey] = useState(false);
    const handleClickShowStreamKey = () => setshowStreamKey((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    return (

        <Box>
            <TextField
                label="Input Stream URL"
                defaultValue={eventSettings.streamUrl}
                margin="normal"
                fullWidth
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">

                            <IconButton onClick={() => handleClick(eventSettings.streamUrl)} color="primary">
                                <ContentCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Stream Key"
                defaultValue={eventSettings.streamKey}
                margin="normal"
                fullWidth
                type={showStreamKey ? "text" : "password"}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowStreamKey}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showStreamKey ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                            <IconButton onClick={() => handleClick(eventSettings.streamKey)} color="primary">
                                <ContentCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />


        </Box>

    );
};
export default AdvanceSettings;
