import React, {useEffect, useState} from 'react'
import {Typography, Grid, styled, Button} from '@mui/material';
import EditProfileModal from "../profile/EditProfileModal";
import ProfileAvatar from "../../components/ProfileAvatar";
import {useSelector} from "react-redux";
import {useGetUserByEmailQuery} from "../../api/userApi";
import Progress from "../../components/Progress";
import {theme} from "../../layout/stylesheet";

const profileImage = "https://static.vecteezy.com/system/resources/previews/005/544/718/original/profile-icon-design-free-vector.jpg";
const RightSide = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10%',
});
const ProfileDetails = styled('div')({
    marginBottom: '2rem',
    fontSize: '20px',
    color: 'white',
    letterSpacing: '.5px',
});
const Profile = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {userEmail} = useSelector((state: any) => state.auth)
    const {data: user, isLoading, refetch} = useGetUserByEmailQuery(userEmail!)
    console.log(`User Data Received : ${JSON.stringify(user)}`)

    useEffect(() => {
        refetch()
    }, [refetch])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    if (isLoading) {
        return <Progress/>
    }

    // @ts-ignore
    return (
        <Grid container spacing={2}
              sx={{background: theme.palette.background.default, height: '100%', width: '100%', justifyContent: 'space-around'}}>
            <Grid item xs={12} sm={3} sx={{background: 'transparent', mt: '5%'}}>
                <Typography variant="h4"
                            sx={{width: '100%', background: 'transparent', fontWeight: 'bold', color: theme.palette.secondary.main,  mb: '5%'}}>
                    {user?.name}
                </Typography>
                <ProfileAvatar avatarSrc={localStorage.getItem('profileAvatar') || profileImage}/>
            </Grid>

            <Grid item xs={12} sm={5} sx={{background: 'transparent', mt: '8%', mb: '5%',ml:'-9%'}}>
                <RightSide>
                    <ProfileDetails>Name: {user?.name}</ProfileDetails>
                    <ProfileDetails>E-mail: {userEmail}</ProfileDetails>
                    {/*<ProfileDetails>Contact Number: {user?.contactNumber}</ProfileDetails>*/}
                    {/*<ProfileDetails>Address: {user?.address}</ProfileDetails>*/}
                </RightSide>
                {/*<Button onClick={handleOpenModal} variant="contained" sx={{background:theme.palette.secondary.main}}>Edit</Button>*/}
                {/*<EditProfileModal isOpen={isModalOpen} onClose={handleCloseModal}/>*/}
            </Grid>
        </Grid>
    );
};
export default Profile;