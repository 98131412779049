import * as React from 'react'
import {Link} from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import BackupIcon from '@mui/icons-material/Backup'
import DateRangeIcon from '@mui/icons-material/DateRange'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import HomeIcon from '@mui/icons-material/Home'
import LiveTvIcon from '@mui/icons-material/LiveTv'
import PersonIcon from '@mui/icons-material/Person'
import {Toolbar} from '@mui/material'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {CSSObject, styled, Theme} from '@mui/material/styles'
import {theme} from "../layout/stylesheet";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240

const pages = [
    {name: 'Home', link: '/home', icon: <HomeIcon/>},
    // { name: 'Activity log', link: '/activity', icon: <FormatAlignLeftIcon /> },
    {name: 'Schedule Live', link: '/schedule-live', icon: <DateRangeIcon/>},
    {name: 'Scheduled List', link: '/scheduled-live-list', icon: <FormatListBulletedIcon/>},
    // {name: 'Upload Video', link: '/vod', icon: <BackupIcon/>},
    // { name: 'Community', link: '/community', icon: <GroupsIcon /> },
    {name: 'Profile', link: '/profile', icon: <PersonIcon/>},
]

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))
export default function MiniDrawer() {
    const [open, setOpen] = React.useState(false)
    const [activePage, setActivePage] = React.useState('/');
    const location = useLocation();

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleLinkClick = (link: string) => {
        setActivePage(link);
    };

    useEffect(() => {
        setActivePage(location.pathname);
    }, [location.pathname]);

    return (
        <>
            <Box sx={{display: 'flex'}}>
                <Drawer
                    variant="permanent"
                    open={open}
                    sx={{
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            backgroundColor: theme.palette.background.default,
                            boxSizing: 'border-box',
                            borderColor: theme.palette.secondary.main
                        },
                    }}
                >
                    <Toolbar/>
                    <DrawerHeader>
                        <div>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    ...(open && {display: 'none'}),
                                }}
                            >
                                <ArrowForwardIcon/>
                            </IconButton>
                        </div>
                        <IconButton
                            onClick={handleDrawerClose}
                            color="inherit"
                            aria-label="close drawer"
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(!open && {display: 'none'}),
                            }}
                        >
                            <ArrowBackIcon/>
                        </IconButton>
                    </DrawerHeader>
                    <List>
                        {pages.map((pages, index) => (
                            <ListItem
                                key={pages.name}
                                disablePadding
                                sx={{display: 'block'}}
                            >
                                {/* const Icon = {pages.name}; */}
                                <Link to={pages.link} style={{textDecoration: "none"}}
                                      onClick={() => handleLinkClick(pages.link)}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                            color: 'primary.main'
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                color: pages.link === activePage ? 'secondary.main' : '#fff',
                                            }}
                                        >
                                            {/* {index % 2 === 0 ? <HomeIcon /> : <MailIcon />} */}
                                            {pages.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={pages.name}
                                            sx={{
                                                opacity: open ? 1 : 0,
                                                color: pages.link === activePage ? 'secondary.main' : '#fff',
                                            }}
                                        />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </Box>
        </>
    )
}
