import {createTheme} from '@mui/material/styles';
import {amber, blue, deepOrange, grey} from '@mui/material/colors';
import {PaletteMode} from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        link: string; // Define the type for customColor
    }
    interface PaletteOptions {
        link?: string; // Define the type for customColor
    }
}

export const getDesignTokens = (mode: PaletteMode) => ({

    palette: {
        mode,
        ...(mode === 'light'
            ? {
                // palette values for light mode
                primary: amber,
                secondary: {
                    main: '#7A4DE5'
                },
                divider: amber[200],
                text: {
                    primary: grey[900],
                    secondary: '#7A4DE5',
                },

            }
            : {
                // palette values for dark mode
                primary: {
                    main: '#FFFFFF',    //text-field label color
                    light: '#FFFFFF',
                    dark: '#181D23'
                },
                secondary: {
                    main: '#7A4DE5',
                    light: '#402f67',
                },
                divider: "#fff",
                background: {
                    default: "#1D2125",  //appbar and sidebar
                    paper: '#181D23',
                },
                text: {
                    primary: '#fff',
                    secondary: '#FFFFFF',  //text-field text color
                    disabled: '#fff',

                },
                link:"#6c5ba7",

            }),
    },
});


