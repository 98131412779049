import React from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {Box, Button,Grid} from '@mui/material'
import Tab from '@mui/material/Tab'
import TitleBar from '../../components/TitleBar'
import CategoryChips from '../../features/CategoryChips'
import DisplayHighlightVideos from '../../features/DisplayHighlightVideos'
import DisplayUploadedVideos from '../../features/DisplayUploadedVideos'

import UploadVideoMadal from "../../features/UploadVideoMadal";
import {Link} from "react-router-dom";
import SortIcon from '@mui/icons-material/Sort';
import {theme} from "../../layout/stylesheet";

const Vod: React.FC = () => {
    const [value, setValue] = React.useState('1')
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }
    const handleSelectedChipsChange = (selectedChips: string[]) => {
        console.log('Selected Chips:', selectedChips)
    }

    return (
        <>
            <Grid container spacing={2}>
                <TitleBar title="Video on Demand" buttons={
                    <Button
                        onClick={handleOpenModal}
                        variant="contained"
                        sx={{background: theme.palette.secondary.main, color: theme.palette.primary.main}}
                    >
                        Upload Video
                    </Button>
                }/>
                <UploadVideoMadal isOpen={isModalOpen} onClose={handleCloseModal}/>
                <Grid item xs={12}>
                    <Box sx={{
                        pt: 2,
                        pl: 6,
                        pr: 6,
                        pb: 2,
                    }}>
                        <CategoryChips onChange={handleSelectedChipsChange}/>
                        <Box sx={{width: '100%', mt:2}}>
                            <TabContext value={value}>
                                <Box sx={{borderBottom: 2, borderColor: 'divider'}}>
                                    <TabList onChange={handleChange} aria-label="Select" >
                                        <Tab label="Video" value="1" sx={{textTransform: 'none'}}/>
                                        <Tab
                                            label="Highlight"
                                            value="2"
                                            sx={{textTransform: 'none', }}
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        m: 0,
                                        p: 0
                                    }}>
                                        <Link to="#">
                                            <Button sx={{textAlign: "right", justifyContent: "flex-end"}}>
                                                Sort by
                                                <SortIcon/>
                                            </Button>
                                        </Link>
                                    </Box>
                                    {/*<Box sx={{pb:2}}>*/}
                                    {/*    <Divider />*/}
                                    {/*</Box>*/}
                                    <DisplayUploadedVideos/>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        m: 0,
                                        p: 0
                                    }}>
                                        <Link to="#">
                                            <Button sx={{textAlign: "right", justifyContent: "flex-end"}}>
                                                Sort by
                                                <SortIcon/>
                                            </Button>
                                        </Link>
                                    </Box>
                                    <DisplayHighlightVideos/>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
export default Vod
