import React from 'react'
import Typography from '@mui/material/Typography'
import {Box, Button} from "@mui/material";
import {Link} from "react-router-dom";

type Props = {}

const TermsAndConditions = (props: Props) => {
    return (
        <Box sx={{m:3}}>
            <Box>
                <Box>
                    <Link to="/register">
                        <Button variant="text" type="submit" size="small"
                                style={{textTransform: 'none', marginBottom:20}}>Go Back</Button>
                    </Link>
                </Box>
            </Box>
            <Typography variant="h2">Terms And Conditions</Typography>

            <Typography variant="body1">

            </Typography>
        </Box>
    )
}

export default TermsAndConditions
