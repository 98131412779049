import {createSlice, PayloadAction} from '@reduxjs/toolkit'


type LiveVideoState = {
    id: string
    url: string
    eventsId:string
}

const initialState: LiveVideoState = {
    id: '',
    url: '',
    eventsId:'',
}

const liveSlice = createSlice({
    name: 'live',
    initialState,
    reducers: {
        liveRequest: (state, action: PayloadAction<LiveVideoState>) => {
            state.id = action.payload.id
            state.url = action.payload.url
            state.eventsId = action.payload.eventsId
        },
    }
})

export const {
    liveRequest,
} = liveSlice.actions;

export default liveSlice.reducer;