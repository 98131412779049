import React, {useEffect, useState, useRef} from "react";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Box, Button, Divider, Grid, TextField, Typography} from "@mui/material";
import {useForgotPasswordMutation} from "../../api/authApi";
import Progress from "../../components/Progress";
import {toast, ToastContainer} from "react-toastify";
import LoginLeftSideImage from "../../components/LoginLeftSideImage";
import {theme} from "../../layout/stylesheet";
import logo from "../../assets/logo/logo.png";

type ResetPasswordValues = {
    username: string;
};

const ForgotPassword = () => {

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<ResetPasswordValues>({mode: "onBlur"});

    const navigate = useNavigate();


    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [forgotPassword] = useForgotPasswordMutation();

    useEffect(() => {
        if (loginError) {
            toast.error(loginError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }, [loginError]);

    const onSubmit = async (data: ResetPasswordValues) => {
        setIsLoading(true);
        setLoginError("");

        try {
            const result: any = await forgotPassword(data);
            console.log(`Forgot Password Result : ${JSON.stringify(result)}`);

            if (result.data?.data) {
                localStorage.setItem("username", data.username);
                navigate('/new-password');

            } else {
                console.log(result);
                setLoginError("Please Use Registered Email.");
                // toast.error('Email is incorrect.', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "colored",
                // });
                // setLoginError("Forgot Password Error.");
            }
        } catch (error) {
            console.log(error);
            setLoginError("An error occurred while sending email.");

        }
        setIsLoading(false);
    };

    if (isLoading) {
        return <Progress/>;
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Box sx={{
                width: '100%',
                position: 'relative',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height: '100vh'
            }}>

                <Box sx={{width: {sm:'80%',md:'80%',lg:'80%',xl:'75%'}, height: 'auto'}}>

                    <Grid container spacing={0}>
                        <Grid item xs={0} md={0} lg={4} xl={3} sx={{display:{xs:'none',sm:'none',lg:'flex'},
                            justifyContent:'right',
                        }}>
                            <LoginLeftSideImage/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={8} xl={9}>
                            <Box sx={{

                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: {xs:'46px',sm:'46px',md:'46px',lg:'46px 0px 0px 46px'},
                                position: 'relative',
                                height:'auto',
                                boxShadow:8,
                                minHeight:'80vh'
                            }}>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    pt: 5,
                                    pl: 3,
                                    pr: 3
                                }}>
                                    <Link to="/">
                                        <ArrowBackIcon/>
                                    </Link>
                                    <Typography variant="h5" sx={{ml: 5, color:theme.palette.secondary.main}}>
                                        Reset your password
                                    </Typography>


                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignContent: 'center',
                                        mt: 20
                                    }}
                                >
                                    <form onSubmit={handleSubmit(onSubmit)} style={{marginRight:'35px',marginLeft:'35px',marginBottom:'80px'}}>
                                        <Box sx={{display: 'flex', flexDirection: 'column', pb: 2}}>

                                            <TextField
                                                sx={{width: "100%", height: "50px"}}
                                                label="Email"
                                                {...register("username", {
                                                    required: true,
                                                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                                                })}
                                                error={!!errors.username}
                                                helperText={errors.username && "Invalid email"}

                                            />
                                            <Typography variant="body2" sx={{mt: 5, color:theme.palette.secondary.main}}>
                                                We will send you an email with confirmation OTP
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                paddingTop: "40px",
                                            }}
                                        >
                                            <Button sx={{ backgroundColor:theme.palette.secondary.main, color:theme.palette.text.primary, ":hover": {
                                                    bgcolor:theme.palette.secondary.light,
                                                }}} type="submit" variant="contained">
                                                Submit
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>


                                {/*<Box sx={{*/}
                                {/*    pt: 20,*/}
                                {/*    pl: 3,*/}
                                {/*    pr: 3*/}
                                {/*}}>*/}
                                {/*    <Divider/>*/}
                                {/*    <Box sx={{mt:2}}>*/}
                                {/*        <Link to="/register">*/}
                                {/*            <Button variant="text" type="submit" size="small"*/}
                                {/*                    style={{textTransform: 'none'}}>*/}
                                {/*                Register an email*/}
                                {/*            </Button>*/}
                                {/*        </Link>*/}
                                {/*    </Box>*/}
                                {/*</Box>*/}

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </>
    );
};

export default ForgotPassword;
