import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup, IconButton, InputAdornment,
    TextField,
    Typography,
} from "@mui/material";

import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {useSignupMutation} from "../api/authApi";
import Progress from "../components/Progress";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {theme} from "../layout/stylesheet";


type RegisterFormValue = {
    fullName: string;
    email: string;
    password: string;
    confirmPassword: string;
};
const Register = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
        getValues,

    } = useForm<RegisterFormValue>({mode: "onBlur"});
    const navigate = useNavigate();
    const [signup] = useSignupMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [registrationError, setRegistrationError] = useState("");
    const [isAgreeTerms, setAgreeTerms] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showconfpass, setshowconfpass] = useState(false);

    const handleClickShowPassword = () => setShowPass((show) => !show);
    const handleClickShowCofPassword = () => setshowconfpass((show) => !show);
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    const handleMouseDownConPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreeTerms(event.target.checked);
        console.log(event.target.checked);
    };

    useEffect(() => {
        if (registrationError) {
            toast.error(registrationError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }, [registrationError]);

    const onSubmit = async (data: RegisterFormValue) => {
        setIsLoading(true);
        setRegistrationError("");

        try {
            const result: any = await signup(data);
            console.log("Signup result:", result);

            if (result.data?.data.user) {
                localStorage.setItem("email", data.email);
                navigate("/account-verification");
            } else {
                setRegistrationError("Error : Email Already Exist.");
            }
        } catch (error) {
            console.log("error", error);
            setRegistrationError("An error occurred while registration.");
        }
        setIsLoading(false);
    };


    // const validateConfirmPassword = (value: string) => {
    //     const {password} = getValues();
    //     return value === password || "Passwords do not match";
    // };

    // @ts-ignore
    const validateConfirmPassword = (value) => {
        const {password} = getValues();
        const noWhiteSpace = /\s/g.test(value);
        if (noWhiteSpace) return 'Whitespace is not allowed';
        return value === password || "Passwords do not match";
        return true;
    };

    if (isLoading) {
        return <Progress/>;
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        <div
            style={{
                // width: "675px",
                // height: "600px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >


            <Box
                sx={{
                    width:{xs:'65%',sm:'65%',md:'50%',lg:'50%',xl:'50%'},
                    pt: 5,
                    pb: 5,
                    pl:6,
                    pr:6,
                    mb:10,
                    borderRadius: "25px",
                    boxShadow: "0px 4px 10px 10px rgba(0, 0, 0, 0.25)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.primary.dark,
                    border: "2px solid",
                    borderColor:theme.palette.secondary.main


                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
                    <Box>
                        <TextField
                            sx={{width: "100%", height: "50px"}}
                            label="User Name"
                            {...register("fullName", {required: true})}
                            error={!!errors.fullName}
                            helperText={errors.fullName && "User name is required"}
                            // style={errors.fullName ? {marginBottom: "10px"} : {marginTop: "20px"}}
                        />
                    </Box>
                    <Box sx={{pt: "40px"}}>
                        <TextField
                            sx={{width: "100%", height: "50px"}}
                            label="Email"
                            {...register("email", {
                                required: true,
                                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                            })}
                            error={!!errors.email}
                            helperText={
                                errors.email ? "Please enter a valid email address" : ""
                            }
                            // style={errors.email ? {marginTop: "10px", marginBottom: "10px"} : {marginTop: "20px"}}
                        />
                    </Box>
                    <Box >
                        <TextField
                            sx={{width: "100%", height: "50px"}}
                            label="Password"
                            type={showPass ? "text" : "password"}
                            {...register("password", {
                                required: true,
                                //pattern: /^(?=.*[A-Z]).{9,}$/,
                                validate: value => value === value.trim() && /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!_])(.{10,})$/.test(value)
                            })}
                            error={!!errors.password}
                            helperText={
                                errors.password
                                    ? "Password must contain at least one lowercase letter,uppercase letter, contain special characters and be at least 10 characters long"
                                    : ""
                            }
                            style={errors.password ? {marginTop: "40px", marginBottom: "20px"} : {marginTop: "40px"}}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPass ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <Box sx={{pt: "46px"}}>
                        <TextField
                            sx={{width: "100%", height: "50px"}}
                            label="Confirm Password"
                            type={showconfpass ? "text" : "password"}
                            {...register("confirmPassword", {
                                required: true,
                                validate: validateConfirmPassword
                            })}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword ? errors.confirmPassword.message ? errors.confirmPassword.message : "Required" : ""}
                            // style={errors.password ? {marginTop: "60px", marginBottom: '20px'} : {marginTop: "28px"}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowCofPassword}
                                            onMouseDown={handleMouseDownConPassword}
                                        >
                                            {showconfpass ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <FormGroup
                        style={{
                            width: "100%",
                            marginTop: "20px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox onChange={handleCheckbox}/>}
                            label={
                                <Typography variant="body1" style={{fontSize: "12px", color: theme.palette.text.primary}}>
                                    By creating an account, you agree to our{" "}
                                    <Link to="/terms-and-conditions" style={{
                                        color:theme.palette.link,
                                        fontWeight:'800',
                                        textDecoration:'none'
                                    }}>terms</Link> and{" "}
                                    <Link to="/terms-and-conditions" style={{
                                        color:theme.palette.link,
                                        fontWeight:'800',
                                        textDecoration:'none'
                                    }}>privacy policy</Link>
                                </Typography>
                            }
                        />
                    </FormGroup>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            marginTop: "20px",
                        }}
                    >
                        <Button style={{color:theme.palette.text.primary,backgroundColor:theme.palette.secondary.main}} type="submit" variant="contained" disabled={!isAgreeTerms}>
                            Sign up
                        </Button>
                    </div>
                </form>
                <Typography variant="body1" style={{marginTop: "10px", color:theme.palette.text.primary}}>
                    Already have an account ? <Link to="/" style={{
                    color:theme.palette.link,
                    fontWeight:'800',
                    textDecoration:'none'
                }}>Sign In</Link>
                </Typography>
            </Box>

            </div>
        </>
    );
};

export default Register;
