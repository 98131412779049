import * as React from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import {Box, Button} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import logo from '../../assets/images/rec-button.png';

export default function RecordFooter() {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                p: 3,
                position: "fixed",
                left: 0,
                bottom: 0,
                width: "100%",
            }}
        >

            <Grid container spacing={2}>
                <Grid item xs={9}>

                </Grid>
                <Grid item xs={3}>
                    <IconButton component={Link} href=""
                                color="inherit">
                        <img
                            src={logo} alt="logo"
                            style={{width: 40, height: 40}} // Add some margin between the image and the icon
                        />
                    </IconButton>
                    <Button variant="outlined" color="error"
                            sx={{
                                marginLeft: '50px'
                            }}>
                        End Stream
                    </Button>
                </Grid>
            </Grid>

        </Box>
    );
}