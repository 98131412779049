import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography} from '@mui/material'
import wallpaper from '../../assets/images/blue wallpaper.jpg'
import logo from '../../assets/logo/logo.png'
import Register from '../../components/Register'
import getRandomInt from "../../components/getRandomNumber";
import ReactTextTransition from "react-text-transition";
import {theme} from "../../layout/stylesheet";

type Props = {}

const RegisterLayout = (props: Props) => {
    const heading = ["Elegant", "The No.1", "Streameg", "Creative"]
    const [randomIndex, setRandomIndex] = useState(0);
    useEffect(() => {
        let interval = setInterval(() => {
            setRandomIndex(getRandomInt(0, heading.length));

        }, 3000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <>
            <Box sx={{
                width: '100%',
                position:'relative',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height:'100vh'
            }}>
                <Box sx={{width: {sm:'90%',md:'90%',lg:'80%',xl:'75%'}, height: 'auto'}}>
                    <Grid container spacing={0}>

                        <Grid item xs={12} md={12} lg={8} xl={9}>
                            <Box sx={{
                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: {xs:'46px',sm:'46px',md:'46px',lg:'46px 0px 0px 46px'},
                                position: 'relative',
                                height:'auto',
                                boxShadow:8,
                                minHeight:'80vh'
                            }}>
                                <Box sx={{pt:5, pl:5, pr:3}}>
                                    <Box sx={{display:{xs:'flex',sm:'flex',md:'flex',lg:'none',xl:'none'},justifyContent:'center'}}>
                                        <img src={logo} width={'80%'} style={{marginTop:'85px'}}/>
                                    </Box>
                                    <Typography variant="h3" sx={{color: theme.palette.primary.main
                                        ,textAlign:{xs:'center',sm:'center',md:'center',lg:'left',xl:'left'},
                                        fontSize:{xs:'2.4rem',sm:'2.4rem',md:'2.4rem',lg:'3rem',xl:'3rem'}
                                    }
                                    }>
                                        Register
                                    </Typography>
                                    <Typography sx={{color: theme.palette.primary.main,
                                        textAlign:{xs:'center',sm:'center',md:'center',lg:'left',xl:'left'}
                                    }}>
                                        Welcome to the Streameg admin panel, Let’s create an account
                                    </Typography></Box>
                                <Box sx={{mt:3}}>

                                    <Register/>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item xs={0} md={0} lg={4} xl={3} sx={{display:{xs:'none',sm:'none',lg:'flex'},
                            justifyContent:'left',
                        }}>
                            <Box sx={{position: 'relative',
                                width:'100%',
                                display:'flex',
                                // justifyContent:'center',
                                alignItems:'left',
                                flexDirection:'column'
                            }}>
                                <img
                                    src={logo}
                                    alt="Logo"
                                    width='40%'
                                    height='auto'
                                    style={{position: 'absolute', top: '5%', left: '15%',}}
                                />

                                <img
                                    src={wallpaper}
                                    alt="wallpaper"
                                    style={{
                                        position: 'absolute',
                                        height: '100%',
                                        zIndex: -1,
                                        width: '100%'
                                        , objectFit: 'cover'
                                        , marginRight: '30px'
                                        , boxShadow: ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
                                    }}
                                />

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexItem: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // pt: "64%",
                                        height:'100%'
                                    }}
                                >
                                    <Typography variant="h3">
                                        We are
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <Typography
                                            variant="h3">
                                            <ReactTextTransition inline>
                                                {<Typography variant="h3" sx={{fontWeight: 'bold'}}>
                                                    {`${heading[randomIndex]}`}
                                                </Typography>}
                                            </ReactTextTransition>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>

    )
}

export default RegisterLayout
