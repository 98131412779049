import React, {useState} from 'react';
import {
    Modal,
    Typography,
    IconButton,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputTags from "../components/InputTags";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import UploadIcon from '@mui/icons-material/Upload';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const UploadVideoModal: React.FC<ModalProps> = ({isOpen, onClose}) => {
    // Form state
    const [videoTitle, setVideoTitle] = useState('');
    const [videoType, setVideoType] = useState('');
    const [tags, setTags] = useState('');
    const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
    const [description, setDescription] = useState('');
    const [videoFile, setVideoFile] = useState<File | null>(null);

    // Form submission handler
    const handleSubmit = () => {
        onClose();
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    backgroundColor: 'white',
                    boxShadow: 24,
                    borderRadius: '10px',
                    p: 0,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px 20px 10px 25px',
                        background: '#014361',
                        color: 'white',
                        borderRadius: '10px 10px 0 0',
                    }}
                >
                    <Typography sx={{pt: 2, pb: 2}} variant="h4">
                        Upload Video
                    </Typography>
                    <IconButton aria-label="close" onClick={onClose} sx={{color: 'white'}}>
                        <CloseIcon/>
                    </IconButton>
                </Box>

                <Box sx={{maxHeight: 'calc(100vh - 250px)', overflowY: 'auto'}}>
                    {/* Modal Content */}
                    <Box sx={{p: 5}}>
                        {/* Video Title */}
                        <TextField
                            required
                            fullWidth
                            label="Video Title"
                            placeholder="Enter Your Video Title"
                            value={videoTitle}
                            onChange={(e) => setVideoTitle(e.target.value)}
                            sx={{mb: 5}}
                        />

                        {/* Video Type */}
                        <FormControl fullWidth sx={{mb: 2}}>
                            <InputLabel id="video-type-label">Video Type</InputLabel>
                            <Select
                                labelId="video-type-label"
                                value={videoType}
                                onChange={(e) => setVideoType(e.target.value)}
                                label="Video Type"
                            >
                                <MenuItem value="video">Video</MenuItem>
                                <MenuItem value="highlight">Highlight</MenuItem>
                            </Select>
                        </FormControl>
                        {/*<InputTags/>*/}
                        <Box sx={{mt: 2, mb: 3}}>
                            Upload Thumbnail Photo :
                            <Box sx={{mt: 1}}>
                                <Button variant="outlined" component="label">
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(e) => {
                                            const file = e.target.files && e.target.files[0];
                                            setThumbnailFile(file);
                                        }}
                                    />
                                    Select Image &nbsp; <AddPhotoAlternateIcon/>
                                </Button>
                            </Box>
                        </Box>

                        {/* Description */}
                        <TextField
                            label="Description"
                            placeholder="Enter Video Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                            sx={{mb: 3}}
                        />

                        {/* Upload Video */}
                        <Box sx={{mb: 4}}>
                            Upload Video :
                            <Box sx={{mt: 1}}>
                                <Button variant="outlined" component="label">
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(e) => {
                                            const file = e.target.files && e.target.files[0];
                                            setVideoFile(file);
                                        }}
                                    />
                                    Select Video &nbsp; <UploadIcon/>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Footer buttons */}
                <Box sx={{display: 'flex', justifyContent: 'flex-end', padding: '10px'}}>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{background: '#014361', textTransform: 'capitalize', marginRight: '10px'}}
                    >
                        Upload Video
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UploadVideoModal;

