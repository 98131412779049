import {Box} from "@mui/material";
import React, {useRef} from 'react';
import { shadows } from '@mui/system';
import Typography from "@mui/material/Typography";
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportIcon from '@mui/icons-material/Report';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

type displayType = string |'flex' | 'none' | undefined ;
type icontype ='error' | 'success' | 'info' | 'warning';
interface props{
    msg:string;
    title:string;
    color?:string;
    display?:displayType;
    icontype?:icontype;
}

function SystemAlert(prop:props){
    /*---------------------Styles------------------------*/
        const container ={

        }
    /*---------------------Styles------------------------*/

    return(
        <Box  sx={{
            width:'100%',
            color:'white',
            background:prop.color?prop.color:'rgba(225,225,225,0.1)',
            pb:3,
            paddingLeft:'50px',
            paddingTop:'100px',
            boxShadow: 8,
            display:prop.display?prop.display:'block',

        }}>
            <Box sx={{mr:3,mt:1}}>
                {
                    (!prop.icontype)?
                        (<InfoIcon/>):
                        (prop.icontype === 'info')?
                            (<InfoIcon/>):
                            (prop.icontype === 'success')?
                                (<CheckCircleOutlineIcon/>):
                                (prop.icontype === 'warning')?
                                    (<ReportProblemIcon/>):
                                    (prop.icontype === 'error')?
                                        (<ReportIcon/>):(<InfoIcon/>)



                }

            </Box>


            <Box>
        <Typography variant={'h5'} fontWeight={'600'}>
            {prop.title}
        </Typography>
            <Typography sx={{mt:2,width:'80%'}}>
                {prop.msg}
            </Typography>
            </Box>
        </Box>
    );

}
export default SystemAlert;